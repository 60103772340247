export default {
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Authentication Required",
  "408": "Request Timeout",
  "500": "An error occured"
};

export const loanErrors = {
  loanNumberRequired: "Loan number is required.",
  attributedOmicronLenderRequired: "Attributed Omicron Lender is required.",
  attributedOmicronBrokerRequired: "Attributed Omicron Broker is required.",
  validAttributedOmicronLender:
    "Please select valid lender and amount for Attributed Omicron Lender.",
  attributedOmicronBrokerRequired: "Attributed Omicron Broker is required.",
  principalRequired: "Principal is required.",
  suburbAndStateRequired: "Suburb and State are required.",
  lowerRateValidation:
    "Lower Rate must be between 2%-80% and less than the Higher Rate",
  higherRateValidation:
    "Higher Rate must be between 2%-80% and greater than the Lower Rate",
  lvrValidation: "LVR must be between 5%-99%",
  statusKeySettled: "You cannot change the Status to Pre-Settlement if the Settlement Date is populated",
  statusKeyDischarged: "You cannot change the Status away from Discharged if the Discharged Date field is populated"
};

export const loanFieldErrors = {
  lvr: loanErrors.lvrValidation,
  loanLenders: loanErrors.attributedOmicronLenderRequired,
  suburbState: loanErrors.suburbAndStateRequired,
  attributedBrokerId: loanErrors.attributedOmicronBrokerRequired,
  principal: loanErrors.principalRequired,
  higherRate: loanErrors.higherRateValidation,
  lowerRate: loanErrors.lowerRateValidation,
  loanNo: loanErrors.loanNumberRequired,
  statusKeySettled: loanErrors.statusKeySettled,
  statusKeyDischarged: loanErrors.statusKeyDischarged
};
