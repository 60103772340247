import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import { memberAction } from "../constants/action-type/index";
import _ from "lodash";
import APIHelper from "../libs/apiHelper";
import APIHandler from "../constants/apiUrl";
import history from "../routes/history";

const {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  USER_DETAIL,
  AUTH_IN_PROGRESS,
  AUTH_ERROR,
  CLEARDOWN,
  USER_AUTHORIZATION
} = memberAction;

/**
 * @typedef IHeader
 * @property {string} title
 * @property {string} action
 * @property {string} page
 * @property {boolean} enableBack
 * @property {boolean} showSettings
 * @property {boolean} showMasterSwitch
 * @property {boolean} showIsProfileActive
 * @property {boolean} hasSwitchProfile
 * @property {boolean} editMode
 * @property {boolean} showNotesButton
 * @property {boolean} showOmicronBDM
 * @property {?string} omicronBdm
 * @property {boolean} showPageTitle
 * @property {boolean=} allowSwitchProfile
 * 
 * @typedef UpdateHeaderProps
 * @property {IHeader} header
 * 
 * @param {UpdateHeaderProps} param 
 * @returns 
 */
export function updateHeader({ header }) {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      let response = { status: 200, data: header };
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data,
              !_.isEmpty(header) ? "UPDATE_HEADER" : "CLEAR_HEADER"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
  };
}

export function getUserData() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const USER_DETAIL_URL = APIHelper.constructEndpoint({
        endpoint: "IDENTITY_URL",
        options: {},
        isMocked: true
      });
      const response = await http.get(USER_DETAIL_URL);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response, USER_DETAIL))
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function login(formData) {
  const { username } = formData;

  return dispatch =>
    new Promise(async (resolve, reject) => {
      let userMode = "";
      const LOGIN_URL = "";
      switch (username) {
        case "admin@email.com":
          userMode = "admin";
          break;
        case "client@email.com":
          userMode = "client";
          break;
        default:
          userMode = "admin";
      }
      const response = await http.get(LOGIN_URL);

      if (response) {
        localStorage.setItem("authentication", JSON.stringify(response.data));
        localStorage.setItem("userMode", userMode);
        if (ResponseHandler.isValidStatus(response)) {
          return resolve(
            dispatch(ResponseHandler.validObject(response, LOGIN))
          );
        } else {
          return resolve(ResponseHandler.inValidObject(response));
        }
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function signUp(formData) {
  //const { firstName, lastName, email, password } = formData;

  return dispatch =>
    new Promise(async (resolve, reject) => {
      const SIGNUP_URL = "";
      const response = await http.post(SIGNUP_URL, formData);
      if (response && response.data) {
        if (Object.keys(response.data).length !== 0) {
          localStorage.setItem("authentication", JSON.stringify(response.data));
          return resolve(
            dispatch({
              type: SIGNUP,
              data: response.data
            })
          );
        } else {
          return resolve(
            dispatch({
              type: "ERROR",
              data: response.statusText
            })
          );
        }
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function forgotPassword(formData) {
  //const { username } = formData;

  return dispatch =>
    new Promise(async (resolve, reject) => {
      const FORGOT_PASSWORD_URL = "";
      const response = await http.post(FORGOT_PASSWORD_URL, formData);
      if (response && response.data) {
        if (Object.keys(response.data).length !== 0) {
          return resolve(
            dispatch({
              type: FORGOT_PASSWORD,
              data: response.data
            })
          );
        } else {
          return resolve(
            dispatch({
              type: "ERROR",
              data: response.statusText
            })
          );
        }
      }
    }).catch(async err => {
      throw err.message;
    });
}

export function logOut() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      localStorage.setItem("authentication", null);
      return resolve({
        status: true
      });
    }).catch(async err => {
      throw err.message;
    });
}

// Auth code copied form global dashbaord
const auth = {};

export function signinUser(callback) {
  return function(dispatch) {
    dispatch({ type: AUTH_IN_PROGRESS });
    auth.signin();
  };
}

export function checkSession() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const response = await auth.checkSession();
    }).catch(async err => {
      throw err.message;
    });
}

export const getUserClaims = role => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const date = new Date();
      const timestamp = date.getTime();
      const url = APIHandler.constructEndpoint({
        endpoint: "IDENTITY_URL",
        isMocked: true
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data.result, "UPDATE_CLAIMS")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const getProfileInfo = () => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      try {
        if (_.isNull(localStorage.getItem("access_token"))) auth.signout();
        const response = await auth.getProfile();
        if (response)
          return resolve(
            dispatch(ResponseHandler.validObject(response, "USER_PROFILE"))
          );
      } catch (ex) {
        if (ex.statusCode === 401) auth.signout();
        throw ex;
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const getUserAuthorization = ({ id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHelper.constructEndpoint({
        endpoint: "CALLBACK",
        isMocked: false
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data.result,
              USER_AUTHORIZATION
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    });
};

export const updateNavBar = ({
  title,
  action,
  enableBack,
  total,
  buttonText
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return dispatch({
        type: "SET_HEADER",
        data: { title, action, enableBack, total, buttonText }
      });
    });
};

export function authError(error) {
  const timestamp = Date.now();
  return {
    type: AUTH_ERROR,
    error,
    timestamp
  };
}

export function signoutUser() {
  history.push("/logout", { local: true });
}

export function cleardown() {
  return {
    type: CLEARDOWN
  };
}

export function updateRouteOptions({ routeOptions }) {
  return dispatch => {
    return new Promise(async (resolve, _reject) => {
      let response = { status: 200, data: routeOptions };
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(response.data, "UPDATE_ROUTE_OPTIONS")
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
  };
}
