import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loansSecurityTypesLookups } from "../../../actions/lookup";
import axios from "axios";
import { mustBeArray } from "../../../libs";

const SecurityTypeLookup = ({ value, handleFieldChange, className }) => {
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  useEffect(() => {
    dispatchAction(
      loansSecurityTypesLookups({ cancelToken: signal.token })
    ).then(resp => {
      setList(mustBeArray(resp));
    });
  }, []);

  useEffect(() => {
    if (value) {
      const securitySubTypesList = list.find(o => o.id === value)
        ?.securitySubTypes;
      handleFieldChange({
        field: "securityTypeKey",
        value,
        securitySubTypesList
      });
    }
  }, [value, list]);

  const handleChange = ({ field, value }) => {
    const securitySubTypesList = list.find(o => o.id === value)
      ?.securitySubTypes;
    handleFieldChange({ field, value, securitySubTypesList });
  };
  return (
    <div className={`flex m-l-16 flex-align-center m-r-20 ${className || ""}`}>
      <b>Security Type</b>
      <Select
        className="w-280 m-l-6"
        value={value}
        onChange={value => handleChange({ field: "securityTypeKey", value })}
        allowClear={false}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SecurityTypeLookup;
