import { Button, Col } from "antd";
import { Row } from "antd/es/grid";
import React, { useContext } from "react";
import { LENDER_USER } from "../../../constants/systemUsers";
import FinderUtilityContext from "../FinderUtilityContext";

const FinderUtilityUserDetailHeader = ({ user }) => {
  const { userType, handleViewDetails, generalView } = useContext(
    FinderUtilityContext
  );
  const idText = userType === LENDER_USER ? "lenderId" : "brokerId";
  const logo = user?.logoFile?.fileUrl;
  return (
    <Row className="m-b-14">
      <Col span={8}>{logo ? <img src={logo} height={83} /> : <span />}</Col>
      <Col span={12}>
        <div className="text-center color-dark f-s-40 p-r-20 f-w-500">
          <span className="color-danger">{user?.matchedLoansCount || 0}</span>
          Matching Loans
        </div>
      </Col>
      <Col span={4} className="text-right p-r-60">
        <Button type="primary" onClick={() => handleViewDetails(user[idText])}>
          Details
        </Button>
      </Col>
    </Row>
  );
};

export default FinderUtilityUserDetailHeader;
