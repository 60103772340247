import { Divider, Icon } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useContext, useMemo } from "react";
import { getItemName, isNullEmptyOrUndefined, mustBeArray } from "../../../libs";
import FinderUtilityContext from "../FinderUtilityContext";
import { defaultFilters, finderFilterTypes } from "../types";
import { MAX_PRINCIPAL_FINDER_LIST, MIN_PRINCIPAL_FINDER_LIST } from "../../../constants";

const DeleteIcon = ({ handleDelete }) => (
  <Icon
    type="close"
    className="bg-disabled color-dark radius-50 p-3 m-r-12 f-s-8 m-l-8 hand"
    onClick={handleDelete}
  />
);

const getPrincipalDisplayName = (value, list) => {
  return list.find(o => o.value === value)?.displayName;
}

const FilterView = ({ data = {} }) => {
  const {
    displayFilter,
    handleDelete,
    priorityList,
    scenarioList,
    securityList,
    settlementList,
    termList
  } = useContext(FinderUtilityContext);
  const { type, value, title } = data;
  const {
    lowerRate,
    higherRate,
    lvr,
    establishmentFee,
    suburbAndState,
    securityType,
    radius,
    priority,
    principal,
    scenario,
    settlementDate,
    term,
    lenderStarsFromBroker,
    brokerStarsFromLender
  } = finderFilterTypes;
  
  const displayAppliedFilters = () => {
    switch (type) {
      case lowerRate:
      case higherRate:
      case lvr:
      case establishmentFee:
        return !isNullEmptyOrUndefined(value.high) && !isNullEmptyOrUndefined(value.low) ? (
          <div className=" flex flex-align-center flex-justify-end f-s-12">
            {`${value.low} - ${value.high}% ${
              [lowerRate, higherRate].includes(type) ? "p/a" : ""
            }`}
          </div>
        ) : null;
      case suburbAndState:
      case radius:
        return value ? value : null;
      case principal:
        return value.high && value.low ? (
          <div className=" flex flex-align-center flex-justify-end f-s-12">
            {`${getPrincipalDisplayName(value.low, MIN_PRINCIPAL_FINDER_LIST)} - ${getPrincipalDisplayName(value.high, MAX_PRINCIPAL_FINDER_LIST)}`}
          </div>
        ) : null;
      case term:
        return value.high && value.low ? (
          <div className=" flex flex-align-center flex-justify-end f-s-12">
            {`${getItemName({
              value: value.low,
              list: termList
            })} - ${getItemName({ value: value.high, list: termList })}`}
          </div>
        ) : null;
      case priority:
        const name = getItemName({ value, list: priorityList });
        return name ? `${name} Ranking` : null;
      case scenario:
        const scenarioName = getItemName({ value, list: scenarioList });
        return scenarioName ?? null;
      case settlementDate:
        const datePeriod = getItemName({ value, list: settlementList });
        return datePeriod ?? null;
      case lenderStarsFromBroker:
      case brokerStarsFromLender:
        return value ? `${value}+` : null;
      case securityType:
        if (isEmpty(securityList)) return null;
        return (
          <div className="width-full flex flex-direction-col">
            {mustBeArray(value).map((data, i) => {
              let subList = [];
              if(i===1){
                subList = securityList.find(o => o.id === value[0])?.securitySubTypes;
              }
              const securityName = getItemName({
                list: i=== 1 ?subList :  securityList,
                value: data
              });

              return (
                <Fragment key={i}>
                  {securityName && (
                    <div
                      className=" flex flex-align-center flex-justify-end f-s-12"
                      index={data}
                    >
                      {securityName}
                      <DeleteIcon
                        handleDelete={() => handleDelete({ index: i, type })}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };

  const displayValue = useMemo(() => {
    return displayAppliedFilters();
  }, [data, priorityList,
    scenarioList,
    securityList,
    settlementList,
    termList]);

  return (
    <Fragment>
      <div className="m-b-2 flex flex-align-center flex-justify-end">
        <span>{title}</span>
        <Icon
          type="right"
          className="color-success f-s-18 f-w-800 p-l-10 p-r-10 hand"
          onClick={() => displayFilter(data)}
        />
      </div>
      {displayValue && (
        <div className="flex flex-align-center flex-justify-end f-s-12">
          {displayValue}{" "}
          {type !== securityType && (
            <DeleteIcon
              handleDelete={() =>
                handleDelete({
                  type,
                  defaultValue: defaultFilters.find(o => o.type === type)
                    ?.value,
                  index: null
                })
              }
            />
          )}
        </div>
      )}
      <Divider className="m-t-10 m-b-10" />
    </Fragment>
  );
};

export default FilterView;
