
import { isSystemAdminUserPolicy } from "../Common/Authorization/policies/isSystemUserAdminPolicy";
import { isSystemUserPolicy } from "../Common/Authorization/policies/isSystemUserPolicy";
import _ from "lodash";
import { isNotSystemUserPolicy } from "../Common/Authorization/policies/isNotSystemUserPolicy";
import { isLenderUserPolicy } from "../Common/Authorization/policies/isLenderUserPolicy";
import { isBrokerUserPolicy } from "../Common/Authorization/policies/isBrokerUserPolicy";
import { isNotLawFirmUserPolicy } from "../Common/Authorization/policies/isLawFirmUserPolicy";

export const ALL_LOANS_OPTION = {
  name: "All Loans",
  url: "/loans",
  id: 19,
};

const commonRoutingOptions = [
  {
    name: "My Profile",
    url: "",
    id: 9,
    policy: isNotSystemUserPolicy
  },
  {
    name: "Add Loan",
    url: "/loans/create",
    id: 1,
    policy: isSystemUserPolicy
  },
  {
    name: "Add Company",
    url: "/companies/create",
    id: 2,
    policy: isSystemUserPolicy
  },
  {
    name: "Marketing Email",
    url: "/marketing/email/create",
    id: 17,
    policy: isSystemAdminUserPolicy
  },
  {
    name: "Add System User",
    url: "/systemUsers/create",
    id: 3,
    policy: isSystemAdminUserPolicy
  },
  {
    name: "System Users Console",
    url: "/users",
    id: 4,
    policy: isSystemUserPolicy
  },
  {
    name: "All Loans",
    url: "/loans",
    id: 5,
    policy: isNotLawFirmUserPolicy
  },
  {
    name: "All Users",
    url: "/users/all",
    id: 19,
    policy: isNotLawFirmUserPolicy
  }, 
  {
    name: "Privacy Settings",
    id: 12,
    action: "privacySettingsOwnProfile",
    url: "",
    policy: isLenderUserPolicy
  },
];

export const EMAIL_SUBSCRIPTIONS_OPTION_SYSTEM_USER = [{
  name: "Email Subscriptions",
  id: 13,
  action: "emailSubscriptions",
  url: "",
  policy: isSystemUserPolicy
}];

export const EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER = [{
  name: "Email Subscriptions",
  id: 13,
  action: "emailSubscriptions",
  url: "",
  policy: isLenderUserPolicy
}];

export const EMAIL_SUBSCRIPTIONS_OPTION_BROKER_USER = [{
  name: "Email Subscriptions",
  id: 13,
  action: "emailSubscriptions",
  url: "",
  policy: isBrokerUserPolicy
}];

export const DEFAULT_ROUTES_SYSTEM_USER = _.concat(commonRoutingOptions, [
  {
    name: "Change your Password",
    url: "/change-password",
    id: 6
  } ,
  {
    name: "Find Broker /Find Lender",
    action: "finderUtility",
    id: 15
  },
]);

export const BROKER_PROFILE_ROUTES_WITH_LENDER_PROFILE__SYSTEM_USER = _.concat(
  commonRoutingOptions,
  EMAIL_SUBSCRIPTIONS_OPTION_SYSTEM_USER,
  [
    {
      name: "Delete Lender Profile from this Record",
      url: "",
      id: 6,
      policy: isSystemUserPolicy,
      action: "deleteLender"
    },
    {
      name: "Add User",
      action: "addUser",
      url: "",
      id: 14,
      policy: isSystemUserPolicy
    },
    {
      name: "Change your Password",
      url: "/change-password",
      id: 7
    },
    {
      name: "Find Broker /Find Lender",
      action: "finderUtility",
      id: 16
    },
    {
      name: "Delete this Account",
      url: "",
      id: 10,
      action: "deleteFinancierAccount",
      policy: isSystemAdminUserPolicy
    }
  ]
);

export const BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER = _.concat(
  commonRoutingOptions,
  EMAIL_SUBSCRIPTIONS_OPTION_SYSTEM_USER,
  [
    {
      name: "Add Lender Profile to this Record",
      url: "",
      id: 6,
      action: "addLender",
      policy: isSystemUserPolicy
    },
    {
      name: "Add User",
      action: "addUser",
      url: "",
      id: 14,
      policy: isSystemUserPolicy
    },
    {
      name: "Change your Password",
      url: "/change-password",
      id: 7
    },
    {
      name: "Find Broker /Find Lender",
      action: "finderUtility",
      id: 16
    },
    {
      name: "Delete this Account",
      url: "",
      id: 10,
      action: "deleteFinancierAccount",
      policy: isSystemAdminUserPolicy
    }
  ]
);

export const LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER = _.concat(
  commonRoutingOptions,
  EMAIL_SUBSCRIPTIONS_OPTION_SYSTEM_USER,
  [
    {
      name: "Delete Broker Profile from this Record",
      url: "",
      id: 6,
      policy: isSystemUserPolicy,
      action: "deleteBroker"
    },
    {
      name: "Add User",
      action: "addUser",
      url: "",
      id: 14,
      policy: isSystemUserPolicy
    },
    {
      name: "Change your Password",
      url: "/change-password",
      id: 7
    },
    {
      name: "Find Broker /Find Lender",
      action: "finderUtility",
      id: 16
    },
    {
      name: "Delete this Account",
      url: "",
      id: 10,
      action: "deleteFinancierAccount",
      policy: isSystemAdminUserPolicy
    },
    {
      name: "Privacy Settings",
      id: 12,
      action: "privacySettings",
      url: "",
      policy: isSystemAdminUserPolicy
    }
  ]
);

export const LAWFIRM_PROFILE_ROUTES_SYSTEM_USER = _.concat(
  commonRoutingOptions,
  [
    {
      name: "Add User",
      action: "addUser",
      url: "",
      id: 14,
      policy: isSystemUserPolicy
    },
    {
      name: "Change your Password",
      url: "/change-password",
      id: 7
    },
    {
      name: "Find Broker /Find Lender",
      action: "finderUtility",
      id: 16,
      policy: isNotLawFirmUserPolicy
    },
    {
      name: "Delete this Account",
      url: "",
      id: 10,
      action: "deleteLawFirmAccount",
      policy: isSystemAdminUserPolicy
    },
  ]
);

export const LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER = _.concat(
  commonRoutingOptions,
  EMAIL_SUBSCRIPTIONS_OPTION_SYSTEM_USER,
  [
    {
      name: "Add Broker Profile to this Record",
      url: "",
      id: 6,
      policy: isSystemUserPolicy,
      action: "addBroker"
    },
    {
      name: "Add User",
      action: "addUser",
      url: "",
      id: 14,
      policy: isSystemUserPolicy
    },
    {
      name: "Change your Password",
      url: "/change-password",
      id: 7
    },
    {
      name: "Find Broker /Find Lender",
      action: "finderUtility",
      id: 16
    },
    {
      name: "Delete this Account",
      url: "",
      id: 10,
      action: "deleteFinancierAccount",
      policy: isSystemAdminUserPolicy
    },
    {
      name: "Privacy Settings",
      id: 12,
      action: "privacySettings",
      url: "",
      policy: isSystemAdminUserPolicy
    }
  ]
);
export const CAN_RESET_PROFILE_PASSWORD = [
  {
    name: "Reset User's Password",
    url: "",
    id: 11,
    action: "resetPassword",
    policy: isSystemAdminUserPolicy
  }
];
export const PROFILE_HAS_NOT_ACCEPTED_INVITATION = [
  {
    name: "Resend User Invitation",
    url: "",
    id: 8,
    action: "resendInvitation",
    policy: isSystemAdminUserPolicy
  }
];

export const PROFILE_EDIT_OPTION = [
  {
    name: "Edit Account",
    action: "editAccount",
    id: 18
  }
]