import React, { useEffect, useState, InputNumber } from "react";
import { connect } from "react-redux";
import { Input, Table } from "antd";
import { getAttributedBrokers } from "../../actions/loans";
import { tableColumnGenerator } from "../../libs/table";
import { mustBeArray } from "../../libs/utilities";
import NumberInput from "../../Common/NumberInput";
import history from "../../routes/history";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../constants/systemUsers";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";

const AttributedBrokers = props => {
  const { user, getAttributedBrokers, match, generalView = false } = props;

  const loanId = match.params.id;

  const [attributedBrokerList, setAttributedBrokerList] = useState([]);
  const [isUserLawfirm, setIsUserLawfirm] = useState(false);

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(user.roles, user.permissions, user);
    setIsUserLawfirm(isLawfirm);
  }

  useEffect(() => {
    getAttributedBrokersList();
  }, [loanId]);

  useEffect(()=>{
    checkIfUserIsLawfirm();
  },[user]);

  const getAttributedBrokersList = async () => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";

    await getAttributedBrokers({
      id: match.params.id,
      userType: userType,
      generalView
    }).then(resp => {
      if (resp.status) {
        setAttributedBrokerList(resp.data);
      }
    });
  };

  let colTemplate =
    attributedBrokerList.length > 1
      ? [
          {
            title: "",
            dataIndex: "loanBrokerName",
            width: 100,
            sorter: false,
            render: (data, record) => {
              return (
                <span>
                  <Input
                    style={{ width: "250px" }}
                    value={data}
                    onClick={() => alert("Broker Id: " + record.loanBrokerId)}
                  ></Input>
                </span>
              );
            }
          }
        ]
      : [
          {
            dataIndex: "loanBrokerName",
            width: 100,
            sorter: false,
            render: (data, record) => {
              return (
                <span>
                  <Input style={{ width: "400px" }} value={data}></Input>
                </span>
              );
            }
          }
        ];

  const columns = tableColumnGenerator(colTemplate);

  const handleBrokerProfileClick = brokerId => {
    if (!generalView) {
      history.push(`/users/${brokerId}/broker`);
    } else {
      history.push(`/general/brokers/${brokerId}`);
    }
  };

  return (
    <div>
      {mustBeArray(attributedBrokerList).map((data, i) => {
        return (
          <div className="m-b-8 m-l-4" key={i} style={{ marginTop: "-6px" }}>
            {data.loanBrokerId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: !isUserLawfirm ? "blue" : "inherit",
                  cursor: !isUserLawfirm ? "pointer" : "default"
                }}
                disabled={false}
                value={data.loanBrokerName}
                onClick={() => {
                  if(!isUserLawfirm){
                    handleBrokerProfileClick(data.loanBrokerId);
                  }
                }}
              ></Input>
            )}
            {!data.loanBrokerId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: "darkred",
                  cursor: "default"
                }}
                disabled={false}
                value={data.loanBrokerName}
              ></Input>
            )}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    attributedBrokers: state.attributedBrokers
  };
};

const mapDispatchToProps = {
  getAttributedBrokers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributedBrokers);
