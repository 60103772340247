import React from "react";
import { useSelector } from "react-redux";

const LoanFormHeader = ({ loanDetails, stateAndSuburb, priority }) => {
  const showNotesAndButton = useSelector(state => state?.member?.header?.showNotesAndButton);
  return (
    <h1 className={`flex flex-wrap m-n-t-106 m-l-220 w-percent-${showNotesAndButton ? "58" : "58"}`}>
      <div>
        <span className="m-r-6">Loan Number</span>
        <span className="color-primary m-r-18"> {loanDetails.loanNo}</span>
      </div>
      <div>
        <span className="m-r-6">Principal</span>
        <span className="color-primary m-r-18">
          {" "}
          {loanDetails.principal
            ? `$${loanDetails.principal.toLocaleString()}`
            : ""}
        </span>
      </div>
      <div>
        <span className="m-r-6">Rate</span>
        <span className="color-primary m-r-18">
          {" "}
          {loanDetails.lowerRate ? loanDetails.lowerRate + "%" : ""}
        </span>
      </div>
      <div>
        <span className="m-r-6">LVR</span>
        <span className="color-primary m-r-18">
          {" "}
          {loanDetails.lvr ? loanDetails.lvr + "%" : ""}
        </span>
      </div>
      <div>
        <span className="m-r-6">Priority</span>
        <span className="color-primary m-r-18"> {priority}</span>
      </div>
      <div>
        <span className="m-r-6">State</span>
        <span className="color-primary m-r-18"> {stateAndSuburb.state}</span>
      </div>
      <div>
        <span className="m-r-6">Suburb</span>
        <span className="color-primary m-r-18"> {stateAndSuburb.suburb}</span>
      </div>
    </h1>
  );
};
export default LoanFormHeader;
