export const finderFilterTypes = {
  suburbAndState: "suburbAndState",
  radius: "radius",
  principal: "principal",
  priority: "priority",
  lowerRate: "lowerRate",
  higherRate: "higherRate",
  establishmentFee: "establishmentFee",
  scenario: "scenario",
  securityType: "securityType",
  lvr: "lvr",
  settlementDate: "settlementDate",
  term: "term",
  brokerStarsFromLender: "brokerStarsFromLender",
  lenderStarsFromBroker: "lenderStarsFromBroker"
};

const {
  suburbAndState,
  radius,
  principal,
  priority,
  lowerRate,
  higherRate,
  establishmentFee,
  scenario,
  securityType,
  lvr,
  settlementDate,
  term,
  brokerStarsFromLender,
  lenderStarsFromBroker
} = finderFilterTypes;

export const defaultFilters = [
  {
    title: "Suburb and State",
    type: suburbAndState,
    value: undefined
  },
  {
    title: "Search Radius",
    type: radius,
    value: undefined
  },
  {
    title: "Principal",
    type: principal,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Priority",
    type: priority,
    value: undefined
  },
  {
    title: "Lower Rate",
    type: lowerRate,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Higher Rate",
    type: higherRate,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Establishment Fee",
    type: establishmentFee,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Scenario",
    type: scenario,
    value: undefined
  },
  {
    title: "Security Type",
    type: securityType,
    value: []
  },
  {
    title: "Lvr",
    type: lvr,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Settlement Date",
    type: settlementDate,
    value: undefined
  },
  {
    title: "Term",
    type: term,
    value: {
      low: undefined,
      high: undefined
    }
  },
  {
    title: "Broker Stars from Lender",
    type: brokerStarsFromLender,
    value: undefined
  },
  {
    title: "Lender Stars from Broker",
    type: lenderStarsFromBroker,
    value: undefined
  }
];
