import { Button, DatePicker, InputNumber, Input, Space } from "antd";
import React from "react";
import AttributedBrokerLookup from "./AttributedBrokerLookup";
import LawFirmLookup from "./LawFormLookup";
import LoanFormHeader from "./LoadFormHeader";
import PartnerLookup from "./PartnerLookup";
import PriorityLookup from "./PriorityLookup";
import ScenarioLookup from "./ScenarioLookup";
import SecuritySubTypeLookup from "./SecuritySubTypeLookup";
import SecurityTypeLookup from "./SecurityTypeLookup";
import SuburbAndStateLookup from "./SuburbAndStateLookup";
import AssociateLookup from "./AssociateLookup";
import StatusLookup from "./StatusLookup";
import RatingsAndReview from "./RatingsAndReview";
import { isNullEmptyOrUndefined } from "../../../libs";
import AttributedLendersForm from "./ArrtibutedLendersForm";
import AttributedLendersView from "./AttributedLendersView";
import AttributedBrokersForm from "./AttributedBrokersForm";
import AttributedBrokersView from "./AttributedBrokersView";
import AddInactiveFinancierConfirmationModal from "./AddInactiveFinancierConfirmationModal";
import moment from "moment";
import ErrorAlertDisplay from "../../../Common/ErrorAlertDisplay";
import _ from "lodash";
import NumberInput from "../../../Common/NumberInput";
import BrokerBdmView from "./BrokerBdmView";
import LenderBdmView from "./LenderBdmView";

const LoanCreateForm = ({
  loanDetails,
  handleFieldChange,
  setAttributedBroker,
  setLawFirm,
  handleCancel,
  handleSave,
  errorsList,
  validateOnBlur,
  saveLoading,
  lendersAddModal,
  brokersAddModal,
  stateAndSuburb,
  priority,
  setPriority,
  createMode
}) => {
  return (
    <div className="m-b-12 m-t-65">
      <LoanFormHeader
        loanDetails={loanDetails}
        stateAndSuburb={stateAndSuburb}
        priority={priority}
      />
      {!_.isEmpty(errorsList) && (
        <ErrorAlertDisplay errorsList={errorsList} className="m-t-40" />
      )}

      <div
        className={`m-t-${
          _.isEmpty(errorsList) ? "50" : "30"
        } flex flex-wrap  `}
      >
        <div className="m-r-16 flex flex-no-wrap m-b-10">
          <b className="m-t-6">Loan No.</b>
          <Input
            value={loanDetails.loanNo}
            className="m-l-5 hideNumberPointers"
            style={{ width: "115px" }}
            onChange={e =>
              handleFieldChange({
                field: "loanNo",
                value: e.currentTarget.value
              })
            }
            hasDecimal={false}
            maxLength={12}
            onBlur={e =>
              validateOnBlur({ field: "loanNo", value: e.currentTarget.value })
            }
          ></Input>
          <span className="p-b-25 m-l-4" style={{ color: "red" }}>
            *{" "}
          </span>
        </div>
        <div className="flex flex-wrap m-b-10">
          <b className="m-n-t-10">
            Attributed<br></br>Lender
          </b>
          <AttributedLendersView loanLenders={loanDetails.loanLenders} />
          <AttributedLendersForm
            loanLenders={loanDetails.loanLenders}
            visible={lendersAddModal.visible}
          />
          <span className="p-b-25 m-l-4" style={{ color: "red" }}>
            *{" "}
          </span>
        </div>
        <div className="flex flex-wrap m-b-10">
          <b className="m-n-t-10">
            Attributed<br></br>Broker
          </b>
          <AttributedBrokersView loanBrokers={loanDetails.loanBrokers} />
          <AttributedBrokersForm
            loanBrokers={loanDetails.loanBrokers}
            visible={brokersAddModal.visible}
          />
          <span className="p-b-25 m-l-4" style={{ color: "red" }}>
            *{" "}
          </span>
        </div>
        {/* <AttributedBrokerLookup
          setAttributedBroker={setAttributedBroker}
          value={loanDetails?.attributedBrokerId}
          associatedBroker={loanDetails?.associatedBroker}
          className="m-b-10"
        /> */}
        <div className="flex flex-wrap m-r-10 m-b-10">
          <b className="m-n-t-10">
            Last<br></br>Edit
          </b>
          <Input
            value={loanDetails.lastEditBy}
            className="m-l-6"
            disabled={true}
            style={{ width: "150px" }}
          ></Input>
        </div>
        <BrokerBdmView
          value={loanDetails.brokerBdmId}
          handleFieldChange={handleFieldChange}
          disabled={createMode}
          className="m-b-10"
        />
        <LenderBdmView
          value={loanDetails.lenderBdmId}
          handleFieldChange={handleFieldChange}
          disabled={createMode}
          className="m-b-10"
        />
      </div>
      <div className="flex flex-wrap flex-align-center">
        <SuburbAndStateLookup
          handleFieldChange={handleFieldChange}
          value={loanDetails?.suburbState}
          className="m-b-10"
        />
        <PriorityLookup
          handleFieldChange={handleFieldChange}
          value={loanDetails?.priorityKey}
          className="m-b-10"
          setPriority={setPriority}
        />
        <div className="m-r-16 m-b-10">
          <b>Principal</b>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            className=" m-l-6 hideNumberPointers"
            value={loanDetails.principal}
            disabled
          />
        </div>
        <span
          className="m-b-24 "
          style={{ color: "red", marginLeft: "-13px", marginRight: "16px" }}
        >
          *{" "}
        </span>

        <div className="m-r-16 m-b-10">
          <b>Lower Rate</b>
          <NumberInput
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className=" m-l-6 hideNumberPointers"
            value={loanDetails.lowerRate}
            onChange={e => handleFieldChange({ field: "lowerRate", value: e })}
            onBlur={e => validateOnBlur({ field: "lowerRate", value: e })}
          />
        </div>
        <div className="m-r-16 m-b-10">
          <b>Higher Rate</b>
          <NumberInput
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className=" m-l-6 hideNumberPointers"
            value={loanDetails.higherRate}
            onChange={e => handleFieldChange({ field: "higherRate", value: e })}
            onBlur={e => validateOnBlur({ field: "higherRate", value: e })}
          />
        </div>
        <div className="m-b-10">
          <b>Establishment Fee (incl GST)</b>
          <InputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            className=" m-l-6 hideNumberPointers"
            value={loanDetails.establishmentFeeWithGst}
            onChange={e =>
              handleFieldChange({ field: "establishmentFeeWithGst", value: e })
            }
            hasDecimal={false}
          />
        </div>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <ScenarioLookup
          value={loanDetails.scenarioKey}
          handleFieldChange={handleFieldChange}
          className="m-b-10"
        />
        <SecurityTypeLookup
          value={loanDetails.securityTypeKey}
          handleFieldChange={handleFieldChange}
          className="m-b-10"
        />
        <SecuritySubTypeLookup
          value={loanDetails.securitySubTypeKey}
          handleFieldChange={handleFieldChange}
          disabled={isNullEmptyOrUndefined(loanDetails.securityTypeKey)}
          className="m-b-10"
        />
        <div className="m-r-16 m-b-10">
          <b>LVR</b>
          <NumberInput
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={loanDetails.lvr}
            onChange={e => handleFieldChange({ field: "lvr", value: e })}
            onBlur={e => validateOnBlur({ field: "lvr", value: e })}
          />
        </div>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <LawFirmLookup
          setLawFirm={setLawFirm}
          value={loanDetails?.lawFirmId}
          lawFirm={loanDetails?.lawFirm}
        />
        <PartnerLookup
          lawFirmId={loanDetails?.lawFirmId}
          value={loanDetails.assignedPartnerId}
          handleFieldChange={handleFieldChange}
        />
        <AssociateLookup
          lawFirmId={loanDetails?.lawFirmId}
          value={loanDetails.assignedAssociateId}
          handleFieldChange={handleFieldChange}
        />
      </div>
      <div className="flex flex-wrap flex-align-center">
        <StatusLookup
          value={loanDetails.statusKey}
          handleFieldChange={handleFieldChange}
          validateOnBlur={validateOnBlur}
        />
        <div className="m-r-16 m-b-10">
          <b>Shortfall</b>
          <NumberInput
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={loanDetails.shortfall}
            onChange={e => handleFieldChange({ field: "shortfall", value: e })}
            hasDecimal={false}
            disabled={!loanDetails.dischargedDate}
          />
        </div>
        <div className="m-r-16 m-b-10">
          <Button
            className=" w-81"
            type="primary"
            icon="file"
            onClick={handleSave}
            loading={saveLoading}
            style={{ float: "right" }}
            disabled={
              loanDetails.loanNo === null ||
              loanDetails.loanNo === "" ||
              (loanDetails.loanLenders === null ||
                loanDetails.loanLenders[0].lenderId === null) ||
              loanDetails.attributedBrokerId === null ||
              loanDetails.suburbState === null ||
              (loanDetails.principal === null || loanDetails.principal === 0)
            }
          >
            Save
          </Button>
        </div>
        <div className="m-r-16 m-b-10">
          <Button
            className="w-81"
            style={{ float: "right" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap flex-align-center ">
        <div className="m-r-12 m-b-10">
          <b>Settlement Date</b>
          <DatePicker
            className="m-l-6"
            value={loanDetails.settlementDate}
            onChange={e =>
              handleFieldChange({ value: e, field: "settlementDate" })
            }
            allowClear={false}
          />
        </div>
        <div className="m-r-12 m-b-10">
          <b>Repayment Date</b>
          <DatePicker
            className="m-l-6"
            value={loanDetails.repaymentDate}
            onChange={e =>
              handleFieldChange({ value: e, field: "repaymentDate" })
            }
            allowClear={false}
          />
        </div>
        <div className="m-r-12 m-b-10">
          <b>Discharged</b>
          <DatePicker
            className="m-l-6"
            value={loanDetails.dischargedDate}
            onChange={e =>
              handleFieldChange({ value: e, field: "dischargedDate" })
            }
            allowClear={false}
            disabled={!loanDetails.settlementDate}
            disabledDate={e =>
              moment(e).isBefore(moment(loanDetails.settlementDate))
            }
          />
        </div>
      </div>

      {/* Broker Ratings */}

      <RatingsAndReview
        ratingsText="Broker stars from Lender"
        reviewText="Broker review by Lender"
        responseText="Broker's response"
        loanDetails={loanDetails}
        fields={{
          rating: "brokerStarsFromLender",
          review: "brokerReviewByLender",
          response: "brokerResponseToLender"
        }}
        handleFieldChange={handleFieldChange}
      />

      {/* Lender Ratings */}

      <RatingsAndReview
        ratingsText="Lender stars from Broker"
        reviewText="Lender review by Broker"
        responseText="Lender's Response"
        handleFieldChange={handleFieldChange}
        loanDetails={loanDetails}
        fields={{
          rating: "lenderStarsFromBroker",
          review: "lenderReviewByBroker",
          response: "lenderResponseToBroker"
        }}
      />

      {/* Lawyers ratings by lenders */}
      <RatingsAndReview
        ratingsText="Lawyer stars from Lender"
        reviewText="Lawyer review by Lender"
        responseText="Lawyer's response"
        handleFieldChange={handleFieldChange}
        loanDetails={loanDetails}
        fields={{
          rating: "lawyerStarsFromLender",
          review: "lawyerReviewByLender",
          response: "lawyerResponseToLender"
        }}
      />

      {/* Lawyer ratings by brokers */}
      <RatingsAndReview
        ratingsText="Lawyer stars from Broker"
        reviewText="Lawyer review by Broker"
        responseText="Lawyer's response"
        handleFieldChange={handleFieldChange}
        loanDetails={loanDetails}
        fields={{
          rating: "lawyerStarsFromBroker",
          review: "lawyerReviewByBroker",
          response: "lawyerResponseToBroker"
        }}
      />
    </div>
  );
};

export default LoanCreateForm;
