import React, { Fragment } from 'react';
import FilterView from './filterView';

const Sidebar = ({filters}) => {
    return<Fragment>
        {
            filters.map((data,i) => {
                return <FilterView data={data} key={i}/>
            })
        }
    </Fragment>

}

export default Sidebar;