import React, { useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import FinderUtilityContext from "./FinderUtilityContext";
import { getItemName, isNullEmptyOrUndefined, getSettlementItemName } from "../../libs";
import { LENDER_USER } from "../../constants/systemUsers";
import { MAX_PRINCIPAL_FINDER_LIST, MIN_PRINCIPAL_FINDER_LIST } from "../../constants";

const Header = ({ filters = [], smallView }) => {
  const {
    priorityList,
    scenarioList,
    securityList,
    termList,
    list,
    userType,
    settlementList,
  } = useContext(FinderUtilityContext);
  const getValue = useCallback(
    type => {
      const value = filters.find(o => o.type === type)?.value;
      return type === "suburbAndState" && value
        ? value.split(", ")?.[0]
        : value;
    },
    [filters]
  );
  const radius = getValue("radius");
  const suburb = getValue("suburbAndState");
  const principal = getValue("principal");
  const priority = getValue("priority");
  const lowerRate = getValue("lowerRate");
  const higherRate = getValue("higherRate");
  const scenario = getValue("scenario");
  const establishmentFee = getValue("establishmentFee");
  const securityType = getValue("securityType");
  const lvr = getValue("lvr");
  const settlementDate = getValue("settlementDate");
  const term = getValue("term");
  const brokerStarsFromLender = getValue("brokerStarsFromLender");
  const lenderStarsFromBroker = getValue("lenderStarsFromBroker");

  const updatedSelectedSecurityType = selectedData => {
    return selectedData.map((data,i) => {
      let subList = [];
      if(i===1){
        subList = securityList.find(o => o.id === selectedData[0])?.securitySubTypes;
      }
      return getItemName({ value: data, list: i=== 1 ? subList : securityList });
    });
  };

  const getPrincipalDisplayValue = (principalValue, principalList) => {
    return principalList.find(o => o.value === principalValue)?.displayName;
  }

  const radiusAndSuburbText = useMemo(() => {
    if (radius && suburb) return `within ${radius} of ${suburb}, `;
    if (radius && !suburb) return `within ${radius}, `;
    if (suburb && !radius) return `within ${suburb}, `;
    return "";
  }, [radius, suburb]);
  const pricipalText = !isNullEmptyOrUndefined(principal.low)
    ? `${getPrincipalDisplayValue(
        principal.low, MIN_PRINCIPAL_FINDER_LIST
      )}-${getPrincipalDisplayValue(principal.high, MAX_PRINCIPAL_FINDER_LIST)} in size, `
    : "";
  const priorityText = priority
    ? `${getItemName({ value: priority, list: priorityList })} priority, `
    : "";
  const lowerRateText = !isNullEmptyOrUndefined(lowerRate.low)
    ? `${lowerRate.low}-${lowerRate.high}% lower rate, `
    : "";
  const higherRateText = !isNullEmptyOrUndefined(higherRate.low)
    ? `${higherRate.low}-${higherRate.high}% higher rate, `
    : "";
  const establishmentFeeText = !isNullEmptyOrUndefined(establishmentFee.low)
    ? `${establishmentFee.low}-${establishmentFee.high}% estab, `
    : "";
  const scenarioText = scenario
    ? `${getItemName({ value: scenario, list: scenarioList })}, `
    : "";
  const securityTypeText = !_.isEmpty(securityType)
    ? `${updatedSelectedSecurityType(securityType).join(" - ")}, `
    : "";
  const lvrText = !isNullEmptyOrUndefined(lvr.low)
    ? `${ lvr.low}-${lvr.high}% lvr, `
    : "";
  const setttlementDateText = settlementDate
    ? `settled in the last ${getSettlementItemName({value: settlementDate, list: settlementList})}, `
    : "";
  const termText = term.low
    ? `term ${getItemName({ value: term.low, list: termList })} - ${getItemName(
        { value: term.high, list: termList }
      )}, `
    : "";
  const brokerStarsFromlenderText = brokerStarsFromLender
    ? `Broker received ${brokerStarsFromLender} stars, `
    : "";
  const lenderStarsFromBrokerText = lenderStarsFromBroker
    ? `Lender received ${lenderStarsFromBroker} stars `
    : "";

  return (
    <div className={`flex flex-wrap ${smallView ? "m-n-t-6 m-b-20" : "m-n-t-59 m-l-260 w-percent-50"} f-s-18 f-w-500 `}>
      {`${list?.total ||
        0} ${userType===LENDER_USER ? "Lenders" : "Brokers"} who have loans ${radiusAndSuburbText}${pricipalText}${priorityText}${lowerRateText}${higherRateText}${establishmentFeeText}${scenarioText}${securityTypeText}${lvrText}${setttlementDateText}${termText}${brokerStarsFromlenderText}${lenderStarsFromBrokerText}`}
    </div>
  );
};

export default Header;
