import React, { useEffect, useState, Fragment } from "react";
import { Table, Spin, Input, message, Popconfirm, Button, Select } from "antd";
import _ from "lodash";
import { tableColumnGenerator } from "../../libs/table";
import history from "../../routes/history";
import {
  asDefaultDisplayFormatDate,
  mustBeArray,
  errorDisplay
} from "../../libs/utilities";
import axios from "axios";
import { connect } from "react-redux";
import { ADMIN_USER } from "../../constants/systemUsers";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { updateHeader } from "../../actions/member";
import { notesDropdownLookUpHandler } from "../../actions/lookup";
import { Visible } from "../../components/Common/visiblityWrapper";

import {
  getSystemUserNotes,
  getNonSystemUserNotes,
  updateFileNotesAsSystemUser,
  updateFileNotesAsNonSystemUser,
  deleteFileNotesAsSystemUser,
  deleteFileNotesAsNonSystemUser,
  setDailyScorePage,
  setNotesAction
} from "../../actions/notes";

const LenderBrokerFileNotes = props => {
  const {
    match,
    updateHeader,
    getSystemUserNotes,
    getNonSystemUserNotes,
    updateFileNotesAsSystemUser,
    updateFileNotesAsNonSystemUser,
    deleteFileNotesAsSystemUser,
    deleteFileNotesAsNonSystemUser,
    notesDropdownLookUpHandler,
    setDailyScorePage,
    dailyScorePage,
    user
  } = props;
  const signal = axios.CancelToken.source();
  const [filesList, setFilesList] = useState([]);
  const [loading, setListLoading] = useState(true);
  const [editedList, setEditedList] = useState([]);
  const [sortInfo, setSortInfo] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [userType, setUserType] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [noteTypesList, setNoteTypesList] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({page: 1, pageSize: 20});

  const tablePagination = {
    current: paginationConfig?.page,
    total: totalRecords,
    pageSize: paginationConfig.pageSize ?? 20
  };

  useEffect(() => {
    updateHeader({
      header: {
        title: " ",
        action: "",
        page: "individual-notes",
        enableBack: false,
        showSettings: true,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: false,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    handleFetch({ actionedRecordId: null, action: "load" });
    getNoteTypes();
    const cleanUp = () => {
      signal.cancel("");
    };

    return cleanUp;
  }, ["fileNotes", paginationConfig]);

  const getNoteTypes = () => {
    setListLoading(true);
    return notesDropdownLookUpHandler({}).then(resp => {
      if (resp.status) {
        setNoteTypesList(mustBeArray(resp.data.data));
        setListLoading(false);
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  };

  const handleFetch = async ({
    actionedRecordId = null,
    action = "update"
  }) => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    let getActionName =
      userType === ADMIN_USER ? getSystemUserNotes : getNonSystemUserNotes;
    setUserType(userType);
    var params = getQueryParams();

    getActionName({
      cancelToken: signal.token,
      queryParams: getQueryParams()
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        if (!actionedRecordId && action != "update") {
          setFilesList(mustBeArray(resp.data));
          setTotalRecords(resp.total);
          setPage(page + 1);
        } else if (action === "delete") {
          let updatedRecord = null;
          let newList = !updatedRecord
            ? _.filter(filesList, o => o.id !== actionedRecordId)
            : filesList.map(file => {
                return file.id === actionedRecordId ? updatedRecord : file;
              });
          setFilesList(newList);
        }
      }
    });
  };

  const handleProfileClick = record => {
    let profileType = record.profileType === "Lender" ? 
      "lender" : record.profileType === "Law Firm" ? "lawfirm" : "broker";
    let profileId =
      record.profileType === "Lender" ? record.lenderID : record.profileType === "Law Firm" ? record.lawFirmID : record.brokerID;
    history.push(`/users/${profileId}/${profileType}`);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationConfig({
      pageSize: 20,
      page: pagination.current
    });

    setSortInfo({
      field: sorter.field,
      order: !sorter.order
        ? undefined
        : sorter.order === "ascend"
        ? "asc"
        : "desc"
    })
  };

  const handleDelete = ({ record }) => {
    let id =
      record &&
      (record.profileType === "Lender" ? record.lenderID : 
        record.profileType === "Law Firm" ? record.lawFirmID :  
          record.brokerID);
    setListLoading(true);

    let recordId;
    if (record.profileType === "Lender") {
      recordId = record && record.lenderFileNoteId;
    } else if (record.profileType === "Law Firm") {
      recordId = record && record.lawFirmFileNoteId;
    }  else {
      recordId = record && record.brokerFileNoteId;
    }

    let payLoad = {
      id: recordId
    };

    if (record.profileType === "Lender") {
      payLoad.lenderFileNoteId = record && record.lenderFileNoteId;
    } else if (record.profileType === "Law Firm") {
      payLoad.lawFirmFileNoteId = record && record.lawFirmFileNoteId;
    }else {
      payLoad.brokerFileNoteId = record && record.brokerFileNoteId;
    }

    let actionName =
      userType === ADMIN_USER
        ? deleteFileNotesAsSystemUser
        : deleteFileNotesAsNonSystemUser;

    actionName({
      payLoad,
      cancelToken: signal.token,
      id,
      profileType: record.profileType
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        message.success(`File note deleted successfully.`);
        handleFetch({
          actionedRecordId: record && record.id,
          action: "delete"
        });
        setNotesAction("delete");
      }
    });
  };

  const handleEdit = ({ record, field, value }) => {
    let validationId = record && record.id;

    if (!editedList.includes(validationId)) {
      setEditedList([...editedList, validationId]);
    }
    let newUpdatedList = [...filesList].map(data => {
      return data.id === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });
    setFilesList(newUpdatedList);
  };

  const updateRecord = ({ record, field, value }) => {
    let validationId = record && record.id;

    if (field === "noteType") {
      value = noteTypesList.find(x => x.key === value);
    }
    let newUpdatedList = filesList.map(data => {
      return data.id === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });
    setFilesList(newUpdatedList);
  };

  const handleSave = ({ record }) => {
    let id;
    setListLoading(true);
    let payLoad = {
      note: record && record.note,
      noteType:
        record &&
        (record.noteType == null || _.isUndefined(record.noteType)
          ? ""
          : record.noteType.key)
    };
    if (record.profileType === "Lender") {
      payLoad.lenderFileNoteId = record && record.lenderFileNoteId;
      id = record && record.lenderID;
    } else if (record.profileType === "Law Firm") {
      payLoad.lawFirmFileNoteId = record && record.lawFirmFileNoteId;
      id = record && record.lawFirmID;
    } 
     else {
      payLoad.brokerFileNoteId = record && record.brokerFileNoteId;
      id = record && record.brokerID;
    }

    let actionName =
      userType === ADMIN_USER
        ? updateFileNotesAsSystemUser
        : updateFileNotesAsNonSystemUser;

    actionName({
      id: id,
      payLoad,
      cancelToken: signal.token,
      profileType: record.profileType
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        message.success(
          `File notes ${
            record && record.newRecord ? "added" : "updated"
          } successfully.`
        );
        let updatedEditedList = _.filter(
          editedList,
          o => o !== (record && record.id)
        );
        setEditedList(updatedEditedList);
        handleFetch({
          actionedRecordId: _.isInteger(record && record.id) ? record.id : null,
          action: "update"
        });
        setNotesAction("update");
      }
    });
  };

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field
      };
    }
    queryParams = {
      ...paginationConfig
    };

    return queryParams;
  };

  let colTemplate =
    userType === ADMIN_USER
      ? [
          {
            title: "Date",
            dataIndex: "date",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "date" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            dataType: "date"
          },
          {
            title: "Taken by",
            dataIndex: "takenBy",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "takenBy" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          },
          {
            title: "Person",
            dataIndex: "personName",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "personName" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            render: (data, record) => {
              return (
                <span
                  onClick={() => handleProfileClick(record)}
                  style={{ color: "blue" }}
                >
                  {data}
                </span>
              );
            }
          },
          {
            title: "Company",
            dataIndex: "companyName",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "companyName" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          },
          {
            title: "Profile",
            dataIndex: "profileType",
            width: 120,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "profileType" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          }
        ]
      : [
          {
            title: "Date",
            dataIndex: "date",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "date" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            render: record => {
              return asDefaultDisplayFormatDate(record);
            }
          },
          {
            title: "Taken by",
            dataIndex: "takenBy",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "takenBy" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          },
          {
            title: "Person",
            dataIndex: "personName",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "personName" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            render: (data, record) => {
              return (
                <span
                  onClick={() => handleProfileClick(record)}
                  style={{ color: "blue" }}
                >
                  {data}
                </span>
              );
            }
          },
          {
            title: "Company",
            dataIndex: "companyName",
            width: 140,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "companyName" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          },
          {
            title: "Profile",
            dataIndex: "profileType",
            width: 120,
            sorter: false,
            sortOrder:
              sortInfo && sortInfo.field === "profileType" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          }
        ];

  colTemplate = _.concat(colTemplate, [
    {
      title: "Notes",
      dataIndex: "note",
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "note" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "70%",
      render: (data, record) => {
        return editedList.includes(record && record.id) ? (
          <Input.TextArea
            rows={2}
            value={data}
            onChange={e =>
              updateRecord({
                record,
                field: "note",
                value: e.currentTarget.value
              })
            }
          />
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: "Note Type",
      dataIndex: "noteType",
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "note" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "9%",
      render: (data, record) => {
        return editedList.includes(record && record.id) ? (
          <Select
            style={{ width: "100%" }}
            notFoundContent={
              <Fragment>
                <Visible visible={loading}>
                  <Spin size="small" />
                  ...
                </Visible>
                <Visible visible={!loading}>No data</Visible>
              </Fragment>
            }
            filterOption={false}
            onChange={e =>
              updateRecord({
                record,
                field: "noteType",
                value: e
              })
            }
            defaultValue={data === null || _.isUndefined(data) ? "" : data.key}
          >
            {mustBeArray(noteTypesList).map(noteType => {
              return (
                <Select.Option key={noteType.key} value={noteType.key}>
                  {noteType.name}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <span>{data === null || _.isUndefined(data) ? "" : data.name}</span>
        );
      }
    }
  ]);

  const actions = [
    {
      dataIndex: "",
      sorter: false,
      width: 100,
      render: record => {
        return editedList.includes(record && record.id) ? (
          <Button
            size="small"
            icon="file"
            type="primary"
            loading={saveLoading}
            onClick={() => handleSave({ record })}
          >
            Save
          </Button>
        ) : (
          <React.Fragment>
            <EditIcon 
              onClick={() => handleEdit({ record })} />
            <Popconfirm
              title="Are you sure you want to delete this file note?"
              onConfirm={() => handleDelete({ record })}
            >
              <DeleteIcon className="m-l-6" />
            </Popconfirm>
          </React.Fragment>
        );
      }
    }
  ];

  const loadMoreContent = () => (
    <div
      style={{
        position: "relative",
        bottom: "10px",
        textAlign: "center",
        paddingTop: 40,
        paddingBottom: 40,
        border: "1px solid #e8e8e8",
        display: loading ? "block" : "none"
      }}
    >
      <Spin tip="Loading..." />
    </div>
  );

  const tableColumn = _.concat(tableColumnGenerator(colTemplate), actions);

  return (
    <Table
        loading={loading}
        pagination={tablePagination}
        pageSize={pageSize}
        columns={tableColumn}
        dataSource={mustBeArray(filesList)}
        rowKey={(record, index) => index}
        onChange={handleTableChange}
        bordered
      />
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender,
    broker: state.profile.broker,
    dailyScorePage: state.notes.page
  };
};

const mapDispatchToProps = {
  updateHeader,
  getSystemUserNotes,
  getNonSystemUserNotes,
  updateFileNotesAsSystemUser,
  updateFileNotesAsNonSystemUser,
  deleteFileNotesAsSystemUser,
  deleteFileNotesAsNonSystemUser,
  notesDropdownLookUpHandler,
  setDailyScorePage,
  setNotesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LenderBrokerFileNotes);
