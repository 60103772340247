import { Select } from "antd";
import React, { useMemo } from "react";
import { AddAndDeleteButton } from "../../../Common/Index";
import NumberInput from "../../../Common/NumberInput";
import RequiredSign from "../../../Common/RequiredSign";

const AddedLendersDisplayAndUpdate = ({
  index,
  data,
  list,
  loanLenders,
  handleChange,
  handleLenderChange,
  handleAdd,
  handleDelete
}) => {

  const filteredList = useMemo(() => {
    return list.reduce( (a,b) => { 
      const isSelected = loanLenders.some(o => o.lenderId === b.id);
      return a.concat(b.id === data.lenderId || !isSelected ? [b] : [])
    },[]);
  },[loanLenders])

  return (
    <div className="m-b-10 flex">
      <Select
        value={data.lenderId}
        onChange={e =>
          handleLenderChange({
            value: e,
            index
          })
        }
        className="w-280 m-l-8 m-r-20"
        allowClear={false}
        placeholder="Lender"
        showSearch
        filterOption
        optionFilterProp={"name"}
      >
        {filteredList.map((lender, index) => {
          return (
            <Select.Option key={lender.id} name={lender.name} value={lender.id}>
              {lender.name}
            </Select.Option>
          );
        })}
      </Select>
      <NumberInput
        className="m-r-6 hideNumberPointers w-200"
        value={data?.contributionAmount}
        onChange={e =>
          handleChange({
            field: "contributionAmount",
            value: e,
            index
          })
        }
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={value => value.replace(/\$\s?|(,*)/g, "")}
        placeholder="Amount"
        hasDecimal={false}
      />
      {index === 0 && <RequiredSign />}
      <AddAndDeleteButton
        index={index}
        className="m-l-20"
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        listSize={loanLenders.length}
      />
    </div>
  );
};
export default AddedLendersDisplayAndUpdate;
