import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { loanErrors } from "../../../constants";
import AddBrokersModal from "./AddBrokersModal";
import AddInactiveFinancierConfirmationModal from "./AddInactiveFinancierConfirmationModal";
import LoanCreateContext from "./LoanCreateContext";

const AttributedBrokersForm = ({ loanBrokers, visible }) => {
  const [tempLoanBrokers, setLoanBrokers] = useState([]);
  const [inactiveBrokerConfirmModal, setInactiveBrokerConfirmModal] = useState({
    saveLoading: false,
    visible: false,
    financier: {}
  });

  const { brokersList, validateOnBlur, handleLoanBrokersChange } = useContext(
    LoanCreateContext
  );

  useEffect(() => {
    if (visible) {
      setLoanBrokers(loanBrokers);
    }
  }, [loanBrokers, visible]);

  const handleAdd = () => {
    setLoanBrokers([
      ...tempLoanBrokers,
      { brokerId: null, omicronBdmId: null }
    ]);
  };

  const handleDelete = e => {
    let updatedList = [...tempLoanBrokers];
    updatedList.splice(e, 1);
    setLoanBrokers(updatedList);
  };

  const handleSave = () => {
    const hasInvalidData = tempLoanBrokers.some(
      o => !o.brokerId || !o.omicronBdmId
    );
    if (hasInvalidData) {
      return message.error(loanErrors.validAttributedOmicronBroker);
    }
    
    handleLoanBrokersChange(tempLoanBrokers);
    validateOnBlur({ field: "loanBrokers", value: tempLoanBrokers });
  };

  const handleChange = ({ index, field, value }) => {
    const updateList = tempLoanBrokers.map((data, i) => {
      return index === i ? { ...data, [field]: value } : data;
    });
    setLoanBrokers(updateList);
  };

  const handleBrokerChange = ({ index, value }) => {
    const broker = brokersList.find(l => {
      return l.id === value;
    });
    
    if(broker.isMasterSwitchOn && broker.isActive){
      addLoanBroker({index, value, broker: broker});
    }else{
      displayInactiveBrokerConfirmModal({index, value, broker: broker})
      
      
    }
  };

  const addLoanBroker= (broker) => {
    console.log(broker)
    const updateList = tempLoanBrokers.map((data, i) => {
      return broker.index === i ? { ...data, brokerId: broker.broker.id, omicronBdmId: broker.broker.omicronBdmId } : data;
    });
    setLoanBrokers(updateList);
    closeInactiveBrokerConfirmModal()
  }

  const closeInactiveBrokerConfirmModal = () => {
    setInactiveBrokerConfirmModal({ visible: false, saveLoading: false, financier: {} });
  };

  const displayInactiveBrokerConfirmModal = (broker) => {
    setInactiveBrokerConfirmModal({ ...inactiveBrokerConfirmModal, visible: true, financier: broker });
  };

  const handleConfirmAddInactiveBroker = (confirm, broker) => {
    if(confirm){
      addLoanBroker(broker);
    }
    else{
      if(loanBrokers.length == 1){
        setLoanBrokers([
          ...tempLoanBrokers
        ]);
      }else {
        handleDelete(broker.index);
      }
      closeInactiveBrokerConfirmModal()
    }
  };

  return (
    <React.Fragment>
      <AddBrokersModal
        loanBrokers={tempLoanBrokers}
        list={brokersList}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleSave={handleSave}
        handleChange={handleChange}
        handleBrokerChange={handleBrokerChange}
        handleDeleteAll={() =>
          setLoanBrokers([{ brokerId: null, omicronBdmId: null }])
        }
      />
      <AddInactiveFinancierConfirmationModal
        confirmModal={inactiveBrokerConfirmModal}
        handleConfirm={handleConfirmAddInactiveBroker}
        message={'This Broker is Profile Paused / Master Switch off are you sure you want to continue?'}
      />
    </React.Fragment>
  );
};
export default AttributedBrokersForm;
