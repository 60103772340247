import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import {
  Button,
  Col,
  Row,
  Input,
  InputNumber,
  Rate,
  Popconfirm,
  message
} from "antd";
import produce from "immer";
import axios from "axios";
import history from "../../routes/history";
import {
  getLoanDetails,
  deleteLoan,
  updateLoanInView,
  checkUserLoanInvolvement
} from "../../actions/loans";
import AttributedLenders from "../../components/Loans/AttributedLenders";
import {
  asDefaultDisplayFormatDate,
  isNullEmptyOrUndefined
} from "../../libs/utilities";
import authService from "../../actions/AuthorizeService";
import _ from "lodash";
import LoanFormHeader from "../../components/Loans/Create/LoadFormHeader";
import AttributedBrokers from "../../components/Loans/AttributedBrokers";
import { evaluatePolicy } from "../../libs";
import {
  isLenderUserPolicy,
  isLenderOwnProfilePolicy
} from "../../Common/Authorization/policies/isLenderUserPolicy";
import {
  isBrokerUserPolicy,
  isBrokerOwnProfilePolicy
} from "../../Common/Authorization/policies/isBrokerUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import {
  isLawFirmUserPolicy,
  isLawFirmOwnProfilePolicy
} from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import {
  ADMIN_USER,
  DUAL_USER,
  BROKER_USER,
  LENDER_USER,
  LAW_FIRM_USER
} from "../../constants/systemUsers";
import {
  EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER,
  LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
  LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER,
  BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
  LAWFIRM_PROFILE_ROUTES_SYSTEM_USER,
  PROFILE_EDIT_OPTION,
  DEFAULT_ROUTES_SYSTEM_USER
} from "../../constants/routeOptions";

import { dummyLoansDetails } from "../../constants/DummyData/loansdetailsview";

const LoansDetailsView = props => {
  const {
    updateHeader,
    match,
    getLoanDetails,
    checkUserLoanInvolvement,
    deleteLoan,
    updateLoanInView,
    user,
    generalView = false
  } = props;
  const header = useSelector(state => state.member.header);
  const [isSystemUser, setIsSystemUser] = useState(false);
  const [isUserLawfirm, setIsUserLawfirm] = useState(false);
  const dispatchAction = useDispatch();
  const [isUserInvolvedAsLender, setIsUserInvolvedAsLender] = useState(false);

  const signal = axios.CancelToken.source();
  const loanId = match.params.id;

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsUserLawfirm(isLawfirm);
  };

  useEffect(() => {
    const auth = async () => {
      const authenticated = await authService.isAuthenticated();
      if (authenticated) history.push(`/loans/${loanId}/view`);
    };
    auth();
  }, []);

  useEffect(() => {
    checkIfUserIsLawfirm();
    updateHeader({
      header: {
        title: " ",
        action: "",
        page: "loans-details-view",
        enableBack: false,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false,
        showSettings: true,
        showNotesButton: false,
        showSearchBox: !isUserLawfirm
      }
    });
    const cleanUp = () => {
      signal.cancel("");
    };

    //
    getLoanData();

    return cleanUp;
  }, [loanId, user]);

  useEffect(() => {
    updateHeader({
      header: {
        showSearchBox: !isUserLawfirm
      }
    });
  }, [isUserLawfirm]);

  const [loanDetails, setLoanDetails] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [userType, setUserType] = useState();
  const [enableLenderFields, setEnableLenderFields] = useState(false);
  const [enableBrokerFields, setEnableBrokerFields] = useState(false);
  const [enableLawyerFields, setEnableLawyerFields] = useState(false);
  const [stateAndSuburb, setStateAndSuburb] = useState({
    state: "",
    suburb: ""
  });

  const [hasChangesInForm, setHasChangesInForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const individualUser = useSelector(
    state => state.authorization && state.authorization.individualUser
  );

  const getLoanData = async () => {
    if (!generalView) {
      let userDetails = await authService.getUser();
      let usersLenderId = userDetails["app.user.lenderid"];
      let usersBrokerId = userDetails["app.user.brokerid"];
      let usersLawfirmId = userDetails["app.user.lawfirmid"];

      let userRole = "";

      setUserRoles(userDetails["role"]);

      let isSystemUser;
      isSystemUser = userDetails.role === "SYSUSER" ? "SYSUSER" : "";
      let isInvolvedBroker = false;
      let isInvolvedLender = false;

      await checkUserLoanInvolvement({
        id: match.params.id
      }).then(resp => {
        if (resp.status) {
          if (
            typeof userDetails["role"] !== "string" &&
            userDetails["role"].length > 1
          ) {
            if (
              resp.data.attributedBrokers.includes(parseInt(usersBrokerId))
              // && userDetails["role"].includes["BROKER"]
            ) {
              setEnableBrokerFields(true);
              isInvolvedBroker = true;
            }

            if (
              resp.data.attributedLenders.includes(parseInt(usersLenderId))
              //  && userDetails["role"].includes("LENDER")
            ) {
              setEnableLenderFields(true);
              isInvolvedLender = true;
            }
          } else {
            if (
              resp.data.attributedBrokers.includes(parseInt(usersBrokerId)) &&
              (userDetails["role"] === "BROKER" ||
                userDetails["role"].includes["BROKER"])
            ) {
              setEnableBrokerFields(true);
              isInvolvedBroker = true;
            }

            if (
              resp.data.attributedLenders.includes(parseInt(usersLenderId)) &&
              (userDetails["role"] === "LENDER" ||
                userDetails["role"].includes("LENDER"))
            ) {
              setEnableLenderFields(true);
              isInvolvedLender = true;
            }

            if (
              resp.data.attributedLawyer === parseInt(usersLawfirmId) &&
              (userDetails["role"] === "LAWFIRM" ||
                userDetails["role"].includes("LAWFIRM"))
            ) {
              setEnableLawyerFields(true);
            }
          }
        }
      });

      setTimeout("1500");
      await getLoanDetails({
        id: match.params.id,
        userType: isSystemUser,
        isUserInvolvedAsLender: isInvolvedLender,
        isUserInvolvedAsBroker: isInvolvedBroker
      }).then(resp => {
        if (resp.status) {
          setLoanDetails(resp.data);
          setStateAndSuburb({
            state: resp.data.state,
            suburb: resp.data.suburb
          });
        }
      });

      if (userDetails["role"].includes("SYSUSER")) {
        userRole = "admin";
        setUserType(userRole);
        setIsSystemUser(true);
      }
    } else {
      await getLoanDetails({
        id: match.params.id,
        userType: "",
        isUserInvolvedAsLender: false,
        isUserInvolvedAsBroker: false,
        generalView
      }).then(resp => {
        if (resp.status) {
          setLoanDetails(resp.data);
          setStateAndSuburb({
            state: resp.data.state,
            suburb: resp.data.suburb
          });
        }
      });
    }
  };

  useEffect(() => {
    updateUsersRouteOptions();
  }, [window.location.href]);

  const updateUsersRouteOptions = async () => {
    let isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    let systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    let isLawFirm = await isLawFirmUserPolicy(user.roles);

    const isOwnProfile =
      (await evaluatePolicy(user, isBrokerOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLenderOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLawFirmOwnProfilePolicy(user.id)));

    const roleType = systemUser
      ? ADMIN_USER
      : isLawFirm
      ? LAW_FIRM_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : isBroker
      ? BROKER_USER
      : "";

    let newRouteOptions;
    if (roleType === DUAL_USER) {
      newRouteOptions = LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER;
    } else if (roleType === LENDER_USER) {
      newRouteOptions = LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER;
    } else if (roleType === BROKER_USER) {
      newRouteOptions = BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER;
    } else if (roleType === LAW_FIRM_USER) {
      newRouteOptions = LAWFIRM_PROFILE_ROUTES_SYSTEM_USER;
    } else {
      newRouteOptions = DEFAULT_ROUTES_SYSTEM_USER;
    }

    newRouteOptions = [...newRouteOptions].map(o => {
      o.name = o.id == 9 ? individualUser.companyName : o.name;
      return o;
    });

    const menuOptions = isOwnProfile
      ? [...EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, ...PROFILE_EDIT_OPTION]
      : [];

    dispatchAction(
      await updateRouteOptions({
        routeOptions: _.concat(newRouteOptions, menuOptions)
      })
    );
  };

  const [lenderHasNoBrokerRating, setLenderHasNoBrokerRating] = useState(false);
  const [lenderHasNoSolicitorRating, setLenderHasNoSolicitorRating] = useState(
    false
  );
  const [brokerHasNoLenderRating, setBrokerHasNoLenderRating] = useState(false);
  const [brokerHasNoSolicitorRating, setBrokerHasNoSolicitorRating] = useState(
    false
  );

  // const [payload, setPayload] = useState({});
  const saveUpdateToHybridView = async () => {
    let payload = {};
    setIsSaving(true);
    if (enableLenderFields || enableBrokerFields || enableLawyerFields) {
      if (enableLenderFields) {
        if (
          loanDetails.brokerStarsFromLender === null ||
          loanDetails.brokerStarsFromLender === 0
        ) {
          setLenderHasNoBrokerRating(true);
          setIsSaving(false);
        } else {
          setLenderHasNoBrokerRating(false);
        }
        if (
          loanDetails.lawyerStarsFromLender === null ||
          loanDetails.lawyerStarsFromLender === 0
        ) {
          setLenderHasNoSolicitorRating(true);
          setIsSaving(false);
        } else {
          setLenderHasNoSolicitorRating(false);
        }

        if (
          loanDetails.brokerStarsFromLender !== null &&
          loanDetails.brokerStarsFromLender > 0 &&
          (loanDetails.lawyerStarsFromLender !== null ||
            loanDetails.lawyerStarsFromLender > 0)
        ) {
          // await preparePayload(loanDetails);
          handleSave();
        }
      } else if (enableBrokerFields) {
        if (
          loanDetails.lenderStarsFromBroker === null ||
          loanDetails.lenderStarsFromBroker === 0
        ) {
          setBrokerHasNoLenderRating(true);
          setIsSaving(false);
        } else {
          setBrokerHasNoLenderRating(false);
        }

        if (
          loanDetails.lawyerStarsFromBroker === null ||
          loanDetails.lawyerStarsFromBroker === 0
        ) {
          setBrokerHasNoSolicitorRating(true);
          setIsSaving(false);
        } else {
          setBrokerHasNoSolicitorRating(false);
        }

        if (
          loanDetails.lenderStarsFromBroker !== null &&
          loanDetails.lenderStarsFromBroker > 0 &&
          (loanDetails.lawyerStarsFromBroker !== null &&
            loanDetails.lawyerStarsFromBroker > 0)
        ) {
          handleSave();
        }
      } else if (enableLawyerFields) {
        handleSave();
      }
    }
  };

  const preparePayload = async loanDetails => {
    let payload;
    if (enableBrokerFields) {
      payload = {
        lenderReviewByBroker: loanDetails.lenderReviewByBroker,
        lenderStarsFromBroker: loanDetails.lenderStarsFromBroker,
        brokerResponseToLender: loanDetails.brokerResponseToLender,
        lawyerReviewByBroker: loanDetails.lawyerReviewByBroker,
        lawyerStarsFromBroker: loanDetails.lawyerStarsFromBroker
      };
    } else if (enableLenderFields) {
      payload = {
        brokerReviewByLender: loanDetails.brokerReviewByLender,
        brokerStarsFromLender: loanDetails.brokerStarsFromLender,
        lenderResponseToBroker: loanDetails.lenderResponseToBroker,
        lawyerReviewByLender: loanDetails.lawyerReviewByLender,
        lawyerStarsFromLender: loanDetails.lawyerStarsFromLender
      };
    } else if (enableLawyerFields) {
      payload = {
        lawyerResponseToLender: loanDetails.lawyerResponseToLender,
        lawyerResponseToBroker: loanDetails.lawyerResponseToBroker
      };
    }
    // setPayload(payload);
    return payload;
  };

  useEffect(() => {}, [
    lenderHasNoBrokerRating,
    lenderHasNoSolicitorRating,
    brokerHasNoLenderRating,
    brokerHasNoSolicitorRating
  ]);

  const handleRatingChange = async () => {
    if (enableLenderFields || enableBrokerFields) {
      if (enableLenderFields) {
        if (
          loanDetails.brokerStarsFromLender != null ||
          loanDetails.brokerStarsFromLender === 0
        ) {
          setLenderHasNoBrokerRating(false);
          setBrokerHasNoLenderRating(false);
        }
        if (
          loanDetails.lawyerStarsFromLender != null ||
          loanDetails.lawyerStarsFromLender === 0
        ) {
          setLenderHasNoSolicitorRating(false);
          setBrokerHasNoSolicitorRating(false);
        }
      }
      if (enableBrokerFields) {
        if (
          loanDetails.lenderStarsFromBroker != null ||
          loanDetails.lenderStarsFromBroker === 0
        ) {
          setBrokerHasNoLenderRating(false);
          setLenderHasNoBrokerRating(false);
        }
        if (
          loanDetails.lawyerStarsFromBroker != null ||
          loanDetails.lawyerStarsFromBroker === 0
        ) {
          setBrokerHasNoSolicitorRating(false);
          setLenderHasNoSolicitorRating(false);
        }
      }
    }
  };

  const handleSave = async () => {
    let id = match.params.id;
    let userType;

    let payload = await preparePayload(loanDetails);

    if (enableLenderFields) {
      userType = LENDER_USER;
    } else if (enableBrokerFields) {
      userType = BROKER_USER;
    } else if (isSystemUser) {
      userType = ADMIN_USER;
    } else if (enableLawyerFields) {
      userType = LAW_FIRM_USER;
    }

    updateLoanInView({
      id,
      payload,
      userType,
      cancelToken: signal.token
    }).then(resp => {
      if (resp.status) {
        let id = match.params.id;
        message.success(`Loan updated successfully.`);
        window.location.reload();
        setIsSaving(false);
      }
    });
  };

  const handleCancel = () => {
    window.location.reload();
  };

  const handleDelete = () => {
    let id = match.params.id;

    let payload = {
      id: id
    };

    deleteLoan({
      id,
      payload,
      cancelToken: signal.token
    }).then(resp => {
      //redirect user to dashboard
      if (resp.status) {
        message.success(`Loan deleted successfully.`);
        history.push("/");
      }
    });
  };

  const handleRedirectEdit = () => {
    let id = match.params.id;
    history.push(`/loans/${id}/edit`);
  };

  return (
    <div className="m-b-12 m-t-80">
      <LoanFormHeader
        loanDetails={loanDetails}
        stateAndSuburb={stateAndSuburb}
        priority={loanDetails?.priority}
      />
      <div className={`m-t-25 flex flex-wrap`}>
        <div className="m-r-16 flex flex-no-wrap  m-b-10">
          <b>Loan No.</b>
          <Input
            className="m-l-6 hideNumberPointers"
            style={{ width: "115px", marginTop: "-6px" }}
            value={loanDetails.loanNo}
            disabled={true}
            maxLength={6}
            hasDecimal={false}
          />
        </div>
        {!generalView && (
          <div className="flex flex-wrap m-b-10 m-r-20">
            <span className="m-n-t-15">
              <b>
                Attributed<br></br>Lender
              </b>
            </span>
            <AttributedLenders {...props}></AttributedLenders>
          </div>
        )}
        <div className="flex flex-wrap m-b-10 m-r-20">
          <span className="m-n-t-15">
            <b>
              Attributed<br></br>Broker
            </b>
          </span>
          <AttributedBrokers {...props}></AttributedBrokers>
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8 m-n-t-15">
            <b>
              Last<br></br>Edit
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled={true}
            value={loanDetails.lastEditBy}
          ></Input>
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8  m-n-t-15">
            <b>
              Broker<br></br>BDM
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled={true}
            value={loanDetails.brokerBdm}
          ></Input>
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8 m-n-t-15">
            <b>
              Lender<br></br>BDM
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled={true}
            value={loanDetails.lenderBdm}
          ></Input>
        </div>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Suburb and State</b>
          </span>
          <Input
            style={{ width: "230px" }}
            disabled={true}
            value={loanDetails.suburbState}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Priority</b>
          </span>
          <Input
            style={{ width: "100px" }}
            disabled={true}
            value={loanDetails.priority}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Principal</b>
          </span>
          <InputNumber
            style={{ width: "150px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            className="m-l-6  hideNumberPointers"
            disabled={true}
            value={loanDetails.principal}
            hasDecimal={true}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Lower Rate</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails.lowerRate}
          />
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Higher Rate</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails.higherRate}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Establishment Fee (incl GST)</b>
          </span>
          <InputNumber
            style={{ width: "145px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            className=" m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails.establishmentFee}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Establishment Fee</b>
          </span>
          <InputNumber
            style={{ width: "80px" }}
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails.establishmentFeePercentage}
          />
        </span>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Scenario</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails.scenario}
            className="m-r-12"
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Security Type</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails.securityType}
            className="m-r-12"
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Security Sub-Type</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails.securitySubType}
            className="m-r-12"
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>LVR</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails.lvr}
          />
        </span>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Lawfirm</b>
          </span>
          <Input
            style={{ width: "200px" }}
            disabled={true}
            value={loanDetails.lawFirm}
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Partner</b>
          </span>
          <Input
            style={{ width: "300px" }}
            disabled={true}
            value={loanDetails.partner}
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Associate</b>
          </span>
          <Input
            style={{ width: "300px" }}
            disabled={true}
            value={loanDetails.associate}
          ></Input>
        </span>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Status</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails.status}
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Shortfall</b>
          </span>
          <InputNumber
            style={{ width: "100px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            disabled={true}
            value={loanDetails.shortfall}
          ></InputNumber>
        </span>
        {!generalView && isSystemUser && (
          <>
            <span className="m-t-12 m-r-12">
              <Button
                onClick={() => handleRedirectEdit()}
                className="w-81"
                style={{ float: "right" }}
              >
                Edit
              </Button>
            </span>
            <span className="m-t-12 m-r-12">
              <Popconfirm
                title="Are you sure you want to Delete this Loan Record, you will not be able to recover it. Do you wish to Proceed?"
                onConfirm={() => handleDelete()}
              >
                <Button className="bg-danger w-81" style={{ float: "right" }}>
                  Delete
                </Button>
              </Popconfirm>
            </span>
          </>
        )}
      </div>
      <div className="flex flex-wrap flex-align-center ">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Settlement Date</b>
          </span>
          <Input
            style={{ width: "150px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails.settlementDate)}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Repayment Date</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails.repaymentDate)}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Discharged</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails.dischargedDate)}
          ></Input>
        </span>

        {!generalView && (
          <span className="m-t-12 m-l-236">
            <span
              className="m-r-12"
              style={
                (enableLenderFields && hasChangesInForm) ||
                (enableBrokerFields && hasChangesInForm) ||
                (enableLawyerFields && hasChangesInForm)
                  ? { display: "" }
                  : { display: "none" }
              }
            >
              <Button
                className="bg-primary"
                style={{ marginRight: "5px" }}
                onClick={() => saveUpdateToHybridView()}
                disabled={isSaving}
              >
                Save
              </Button>
              <Button onClick={() => handleCancel()} disabled={isSaving}>
                Cancel
              </Button>
            </span>
          </span>
        )}
      </div>
      <Row className="m-t-12">
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker stars from Lender</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails.brokerStarsFromLender}
              disabled={!enableLenderFields}
              onChange={e => {
                setHasChangesInForm(true);
                handleRatingChange();
                setLoanDetails(
                  produce(loanDetails, x => {
                    x.brokerStarsFromLender = e;
                  })
                );
              }}
            ></Rate>
            <br />
            {enableLenderFields && lenderHasNoBrokerRating && (
              <span style={{ color: "red", opacity: "75%", paddingTop: "5px" }}>
                Your rating of the Broker goes here. Giving a Broker rating is
                required by the Omicron Platform
              </span>
            )}
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker review by Lender</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableLenderFields ||
              (enableLenderFields &&
                (loanDetails.brokerStarsFromLender == null ||
                  loanDetails.brokerStarsFromLender == 0))
            }
            className="loan-detail-text-area "
            placeholder={
              enableLenderFields &&
              (loanDetails.brokerStarsFromLender != null &&
                loanDetails.brokerStarsFromLender > 0 &&
                isNullEmptyOrUndefined(loanDetails.brokerReviewByLender))
                ? "Give your review of the Broker here. Giving a review is not mandatory however it is always good for your relationship with a Broker to say something nice."
                : ""
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.brokerReviewByLender = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.brokerReviewByLender}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            className="loan-detail-text-area "
            placeholder={
              enableBrokerFields &&
              (!isNullEmptyOrUndefined(loanDetails.brokerReviewByLender) &&
                isNullEmptyOrUndefined(loanDetails.brokerResponseToLender))
                ? "Your response goes here. If the Lender said something nice, say something nice back. If they criticised you give; them a call and see if you can get them to take it down. If they refuse to put your side of the story in a professional calm fashion, say you're sorry they feel that way etc. This always look better than a rant"
                : ""
            }
            disabled={
              !enableBrokerFields ||
              (enableBrokerFields &&
                isNullEmptyOrUndefined(loanDetails.brokerReviewByLender))
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.brokerResponseToLender = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.brokerResponseToLender}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender stars from Broker</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear
              value={loanDetails.lenderStarsFromBroker}
              disabled={!enableBrokerFields}
              onChange={e => {
                setHasChangesInForm(true);
                handleRatingChange();
                setLoanDetails(
                  produce(loanDetails, x => {
                    x.lenderStarsFromBroker = e;
                  })
                );
              }}
            ></Rate>
            <br />
            {enableBrokerFields && brokerHasNoLenderRating && (
              <span style={{ color: "red", opacity: "75%", paddingTop: "5px" }}>
                Your rating of the Lender goes here. Giving a Lender rating is
                required by the Omicron Platform
              </span>
            )}
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender review by Broker</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableBrokerFields ||
              (enableBrokerFields &&
                (loanDetails.lenderStarsFromBroker === null ||
                  loanDetails.lenderStarsFromBroker === 0))
            }
            className="loan-detail-text-area "
            placeholder={
              enableBrokerFields &&
              (loanDetails.lenderStarsFromBroker != null &&
                isNullEmptyOrUndefined(loanDetails.lenderReviewByBroker))
                ? "Give your review of the Lender here. Giving a review is not mandatory however it is always good for your relationship with a Lender to say something nice."
                : ""
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lenderReviewByBroker = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.lenderReviewByBroker}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableLenderFields ||
              (enableLenderFields &&
                isNullEmptyOrUndefined(loanDetails.lenderReviewByBroker))
            }
            className="loan-detail-text-area "
            placeholder={
              enableLenderFields &&
              (!isNullEmptyOrUndefined(loanDetails.lenderReviewByBroker) &&
                isNullEmptyOrUndefined(loanDetails.lenderResponseToBroker))
                ? "Your response goes here. If the Broker said something nice, say something nice back. If they criticised you give; them a call and see if you can get them to take it down. If they refuse to put your side of the story in a professional calm fashion, say you're sorry they feel that way etc. This always look better than a rant"
                : ""
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lenderResponseToBroker = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.lenderResponseToBroker}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer stars from Lender</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails.lawyerStarsFromLender}
              disabled={!enableLenderFields}
              onChange={e => {
                setHasChangesInForm(true);
                handleRatingChange();
                setLoanDetails(
                  produce(loanDetails, x => {
                    x.lawyerStarsFromLender = e;
                  })
                );
              }}
            ></Rate>{" "}
            <br />
            {enableLenderFields && lenderHasNoSolicitorRating && (
              <span style={{ color: "red", opacity: "75%", paddingTop: "5px" }}>
                Your rating of Lawyer goes here. Giving a Lawyer rating is
                required by the Omicron Platform
              </span>
            )}
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer review by Lender</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableLenderFields ||
              (enableLenderFields &&
                (loanDetails.lawyerStarsFromLender == null ||
                  loanDetails.lawyerStarsFromLender == 0))
            }
            value={loanDetails.lawyerReviewByLender}
            className="loan-detail-text-area "
            placeholder={
              enableLenderFields &&
              (loanDetails.lawyerStarsFromLender != null &&
                loanDetails.lawyerStarsFromLender > 0 &&
                isNullEmptyOrUndefined(loanDetails.lawyerReviewByLender))
                ? "Give your review of the Lawyer here. Giving a review is not mandatory however it is always good for your relationship with a Lawyer to say something nice."
                : ""
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lawyerReviewByLender = e.currentTarget.value;
                })
              );
            }}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableLawyerFields ||
              (enableLawyerFields &&
                isNullEmptyOrUndefined(loanDetails.lawyerReviewByLender))
            }
            className="loan-detail-text-area "
            value={loanDetails.lawyerResponseToLender}
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lawyerResponseToLender = e.currentTarget.value;
                })
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer stars from Broker</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails.lawyerStarsFromBroker}
              disabled={!enableBrokerFields}
              onChange={e => {
                setHasChangesInForm(true);
                handleRatingChange();
                setLoanDetails(
                  produce(loanDetails, x => {
                    x.lawyerStarsFromBroker = e;
                  })
                );
              }}
            ></Rate>
            <br></br>
            {enableBrokerFields && brokerHasNoSolicitorRating && (
              <span style={{ color: "red", opacity: "75%", paddingTop: "5px" }}>
                Your rating of the Lawyer goes here. Giving a Lawyer rating is
                required by the Omicron Platform
              </span>
            )}
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer review by Broker</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableBrokerFields ||
              (enableBrokerFields &&
                (loanDetails.lawyerStarsFromBroker == null ||
                  loanDetails.lawyerStarsFromLender == 0))
            }
            className="loan-detail-text-area"
            placeholder={
              enableBrokerFields &&
              (loanDetails.lawyerStarsFromBroker != null &&
                loanDetails.lawyerStarsFromBroker > 0 &&
                isNullEmptyOrUndefined(loanDetails.lawyerReviewByBroker))
                ? "Give your review of the Lawyer here. Giving a review is not mandatory however it is always good for your relationship with a Lawyer to say something nice."
                : ""
            }
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lawyerReviewByBroker = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.lawyerReviewByBroker}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled={
              !enableLawyerFields ||
              (enableLawyerFields &&
                isNullEmptyOrUndefined(loanDetails.lawyerReviewByBroker))
            }
            className="loan-detail-text-area "
            onChange={e => {
              setHasChangesInForm(true);
              setLoanDetails(
                produce(loanDetails, x => {
                  x.lawyerResponseToBroker = e.currentTarget.value;
                })
              );
            }}
            value={loanDetails.lawyerResponseToBroker}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender
  };
};

const mapDispatchToProps = {
  updateHeader,
  getLoanDetails,
  checkUserLoanInvolvement,
  deleteLoan,
  updateLoanInView
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoansDetailsView);
