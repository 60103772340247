import { Button } from "antd";
import React from "react";

const AddAndDeleteButton = ({
  index,
  className,
  handleAdd,
  handleDelete,
  listSize
}) => {
  return (
    <div className={className || ""}>
      {index !== 0 && <Button icon="minus" onClick={e => handleDelete(index)} />}
      {index + 1 === listSize && (
        <Button icon="plus" onClick={handleAdd} className="m-l-10" />
      )}
    </div>
  );
};

export default AddAndDeleteButton;
