import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import { processRequest } from "./asyncActionProcessor";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import {
  LENDERS_CONSTRUCTION_SECURITY_TYPE,
  LENDERS_DISTANCES_TYPE,
  LENDERS_FIRST_RANKING_SECURITY_TYPE,
  LENDERS_LAND_BANK_AGRICULTURE_TYPE,
  LENDERS_LAND_BANK_ZONING_TYPE,
  LENDERS_MEZZANINE_SECURITY_TYPE,
  LENDERS_SECOND_RANKING_SECURITY_TYPE,
  LENDERS_STATES_REGIONS_TYPE,
  LENDERS_STATES_SUBURBS_TYPE
} from "../constants/lookup";
import { requestBuilder } from "../libs/utilities";
import { ADMIN_USER, LENDER_USER } from "../constants/systemUsers";
import { GET_BROKER_MATCHES_STATUS_LOOKUP } from "../constants/action-type/brokers";
import environment from "../constants/env";

export function positionsLookup() {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "POSITIONS_LOOKUP_URL",
        options: {}
      });
      const response = await http.get(url);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response, "LIST_USERS_POSITION"))
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
}

export const statesLookup = () => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "STATES_LOOKUP_URL",
        options: {}
      });
      const response = await http.get(
        `${environment.baseurl}/api/users/states/search-lookup`
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response, "LIST_STATES"))
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const getOmicronBdmLookup = ({
  search=""
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANY_OMICRON_BDM_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      try {
        const response = await http.get(url);
        return(resolve(processRequest(dispatch, response, "COMPANY_OMICRON_BDM_LOOKUP")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

//NOTES DROPDOWN
export const notesDropdownLookUpHandler = ({}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "NOTE_TYPE_URL"
      });
      const response = await http.get(
        `${environment.baseurl}/api/notes/note-type-dropdown`
      );
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(ResponseHandler.validObject(response, "LIST_STATES"))
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

//UNIVERSAL SEARCH BOX DROPDOWN
export const searchBoxHandler = ({
  queryParams = {},
  action,
  userType,
  id,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let action_match = true;
      let response = {
        status: 200
      };
      let url = "";
      const queryOptions = {
        page: 1,
        ...queryParams
      };

      switch (action) {
        case "SEARCH_BOX_FOR_SYSTEM_USER":
          url = APIHandler.constructEndpoint({
            endpoint: "SEARCH_BOX_FOR_SYSTEM_USER_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
          case "SEARCH_BOX_FOR_LAW_FIRM":
            url = APIHandler.constructEndpoint({
              endpoint: "SEARCH_BOX_FOR_LAW_FIRM_URL"
            });
            response = await requestBuilder(null, url, queryOptions);
            break;
        case "SEARCH_BOX_FOR_LENDER_USER":
          url = APIHandler.constructEndpoint({
            endpoint: "SEARCH_BOX_FOR_LENDER_USER_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case "SEARCH_BOX_FOR_BROKER_USER":
          url = APIHandler.constructEndpoint({
            endpoint: "SEARCH_BOX_FOR_BROKER_USER_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case "SEARCH_BOX_FOR_DUAL_USER":
          url = APIHandler.constructEndpoint({
            endpoint: "SEARCH_BOX_FOR_DUAL_USER_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
      }
      if (action_match) {
        if (ResponseHandler.isValidStatus(response)) {
          return resolve(
            dispatch(
              ResponseHandler.validObject(
                response.data && response.data.result
                  ? response.data.result
                  : response.data,
                action || "MATCHES_LOOKUP"
              )
            )
          );
        } else {
          return resolve(ResponseHandler.inValidObject(response));
        }
      } else {
        return resolve({ status: false });
      }
    });
};

// Lenders
export const lendersLookUpHandler = ({
  queryParams = {},
  action,
  userType,
  id,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let action_match = true;
      let response = {
        status: 200
      };
      let url = "";
      const queryOptions = {
        page: 1,
        ...queryParams
      };

      switch (action) {
        case LENDERS_FIRST_RANKING_SECURITY_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_FIRST_RANKING_SECURITY_TYPE_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_SECOND_RANKING_SECURITY_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_SECOND_RANKING_SECURITY_TYPE_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_CONSTRUCTION_SECURITY_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_CONSTRUCTION_SECURITY_TYPE_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_MEZZANINE_SECURITY_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_MEZZANINE_SECURITY_TYPE_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_LAND_BANK_ZONING_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_LAND_BANK_ZONING_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_LAND_BANK_AGRICULTURE_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_LAND_BANK_AGRICULTURE_TYPE_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_DISTANCES_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_DISTANCES_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_STATES_SUBURBS_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_STATES_SUBURBS_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case LENDERS_STATES_REGIONS_TYPE:
          url = APIHandler.constructEndpoint({
            endpoint: "LENDERS_STATES_REGIONS_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        case "LENDER_MATCHES_STATUS_LOOKUP":
          url = APIHandler.constructEndpoint({
            endpoint:
              userType === ADMIN_USER
                ? apiUrls.LENDERS_ADMIN_MATCHES_STATUS_LOOKUP_URL
                : apiUrls.LENDERS_MATCHES_STATUS_LOOKUP_URL,
            options: { id }
          });
          response = await http.get(url, cancelToken);
          break;
        case "LENDERS_SEARCH_LOOKUP":
          url = APIHandler.constructEndpoint({
            endpoint:
              userType === ADMIN_USER
                ? "LENDERS_SEARCH_LOOKUP_SYSTEM_USER_URL"
                : "LENDERS_SEARCH_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions);
          break;
        default:
          action_match = false;
          break;
      }

      if (action_match) {
        if (ResponseHandler.isValidStatus(response)) {
          return resolve(
            dispatch(
              ResponseHandler.validObject(
                response.data && response.data.result
                  ? response.data.result
                  : response.data,
                action || "MATCHES_LOOKUP"
              )
            )
          );
        } else {
          return resolve(ResponseHandler.inValidObject(response));
        }
      } else {
        return resolve({ status: false });
      }
    }).catch(async err => {
      throw err.message;
    });
};

// Lenders
export const brokersLookUpHandler = ({
  queryParams = {},
  action,
  userType,
  id,
  cancelToken
}) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      let action_match = true;
      let response = {
        status: 200
      };
      let url = "";
      const queryOptions = {
        page: 1,
        ...queryParams
      };

      switch (action) {
        case "BROKERS_SEARCH_LOOKUP":
          url = APIHandler.constructEndpoint({
            endpoint:
              userType === ADMIN_USER
                ? "BROKERS_SEARCH_LOOKUP_SYSTEM_USER_URL"
                : "BROKERS_SEARCH_LOOKUP_URL"
          });
          response = await requestBuilder(null, url, queryOptions, cancelToken);
          break;
        case GET_BROKER_MATCHES_STATUS_LOOKUP:
          url = APIHandler.constructEndpoint({
            endpoint:
              userType === ADMIN_USER
                ? apiUrls.LENDERS_ADMIN_MATCHES_STATUS_LOOKUP_URL
                : apiUrls.LENDERS_MATCHES_STATUS_LOOKUP_URL,
            options: { id }
          });
          response = await http.get(url, cancelToken);
          break;
        default:
          action_match = false;
          break;
      }

      if (action_match) {
        if (ResponseHandler.isValidStatus(response)) {
          return resolve(
            dispatch(
              ResponseHandler.validObject(
                response.data && response.data.result
                  ? response.data.result
                  : response.data,
                action || "MATCHES_LOOKUP"
              )
            )
          );
        } else {
          return resolve(ResponseHandler.inValidObject(response));
        }
      } else {
        return resolve({ status: false });
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const listLendersMatchesLookup = ({ cancelToken, id, userType }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.LENDERS_ADMIN_MATCHES_STATUS_LOOKUP_URL
            : apiUrls.LENDERS_MATCHES_STATUS_LOOKUP_URL,
        options: { id }
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "LIST_LENDERS_MATCHES_LOOKUP"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      console.log("err", err);
      throw err.message;
    });
};

// LOANS LOOKUPS

export const loansSecurityTypesLookups = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_SECURITY_TYPE_LOOKUP,
        options: { }
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};


export const loansStatesAndSuburbsLookups = ({ cancelToken, search="" }) => {
  return async() => {
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_SUBURB_STATES_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansStatusLookups = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_STATUS_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansScenarioLookups = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_SCENARIO_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansBrokersLookups = ({ cancelToken, search }) => {
  return async() => {
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_BROKERS_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansLendersLookups = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_LENDERS_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansPartnerAssociateLookup = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_PARTNER_ASSOCIATE_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansLawFirmLookup = ({cancelToken, search = '' }) => {
  return async() => {
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_LAWFIRM_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      const response = await http.get(url, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansLawFirmUsersLookup = ({cancelToken, id, search = '' }) => {
  return async() => {
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_LAWFIRM_USERS_LOOKUP,
        options: { id }
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      const response = await http.get(url, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

export const loansPiorityLookup = ({ cancelToken }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.LOANS_PRIORITY_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

// TODO: Update this to get omicronBDM list
export const omicronBDMLookups = ({ cancelToken, search="" }) => {
  return async() => {
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.COMPANY_OMICRON_BDM_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

/**
 * 
 * @param {{cancelToken: any, search?: string}} param0 
 * @returns {Promise<import('../libs/types').IMarketingCompanyLookupItem[]>}
 */
export const marketingCompaniesLookup = ({ cancelToken, search = "" }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_COMPANIES_URL
    });
    const url = search ? `${apiUrl}?search=${search}` : apiUrl;
    const response = await http.get(url, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any, search?: string}} param0 
 * @returns {Promise<import('../libs/types').IMarketingUserLookupItem[]>}
 */
export const marketingUsersLookup = ({ cancelToken, search = "" }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_USERS_URL
    });
    const url = search ? `${apiUrl}?search=${search}` : apiUrl;
    const response = await http.get(url, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any}} param0 
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>} 
 */
export const marketingStatesLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_STATE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any}} param0 
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>} 
 */
export const marketingUserTypesLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_USER_TYPE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any}} param0 
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>} 
 */
export const marketingVisibleUserLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_VISIBLE_USER_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};
/**
 * 
 * @param {{cancelToken: any}} param0 
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>} 
 */
export const marketingCategorySubscribeLookup = ({ cancelToken}) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_CATEGORY_SUBSCRIBE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any}} param0 
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>} 
 */
export const marketingDaysSubscribeLookup = ({ cancelToken}) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_DAYS_SUBSCRIBE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * 
 * @param {{cancelToken: any, search:string}} param0 
 * @returns {Promise<import("../libs/types").IMarketingSenderLookupItem[]>} 
 */
export const marketingSenderLookup = ({ cancelToken, search}) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.MARKETING_LOOKUP_SENDERS_URL
    });
    const url = search ? `${apiUrl}?search=${search}` : apiUrl;
    const response = await http.get(url, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};