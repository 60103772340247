import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { loanErrors } from "../../../constants";
import AddLendersModal from "./AddLendersModal";
import AddInactiveFinancierConfirmationModal from "./AddInactiveFinancierConfirmationModal";
import LoanCreateContext from "./LoanCreateContext";

const AttributedLendersForm = ({ loanLenders, visible }) => {
  const [tempLoanLenders, setLoanLenders] = useState([]);
  const [inactiveLenderConfirmModal, setInactiveLenderConfirmModal] = useState({
    saveLoading: false,
    visible: false,
    financier: {}
  });

  const { lendersList, validateOnBlur, handleLoanLendersChange } = useContext(
    LoanCreateContext
  );

  useEffect(() => {
    if (visible) {
      setLoanLenders(loanLenders);
    }
  }, [loanLenders, visible]);

  const handleAdd = () => {
    setLoanLenders([
      ...tempLoanLenders,
      { lenderId: null, contributionAmount: null, omicronBdmId: null }
    ]);
  };

  const handleDelete = e => {
    let updatedList = [...tempLoanLenders];
    updatedList.splice(e, 1);
    setLoanLenders(updatedList);
  };

  const handleSave = () => {
    const hasInvalidData = tempLoanLenders.some(
      o => !o.lenderId || !o.contributionAmount || !o.omicronBdmId
    );
    if (hasInvalidData) {
      return message.error(loanErrors.validAttributedOmicronLender);
    }
    //handleFieldChange({ field: "loanLenders", value: tempLoanLenders });
    handleLoanLendersChange(tempLoanLenders);
    validateOnBlur({ field: "loanLenders", value: tempLoanLenders });
  };

  const handleChange = ({ index, field, value }) => {
    const updateList = tempLoanLenders.map((data, i) => {
      return index === i ? { ...data, [field]: value } : data;
    });
    setLoanLenders(updateList);
  };

  const handleLenderChange = ({ index, value }) => {
    const lender = lendersList.find(l => {
      return l.id === value;
    });
    
    if(lender.isMasterSwitchOn && lender.isActive){
      addLoanLender({index, value, lender});
    }else{
      displayInactiveLenderConfirmModal({index, value, lender})
      
      
    }
  };

  const addLoanLender= (lender) => {
    const updateList = tempLoanLenders.map((data, i) => {
      return lender.index === i ? { ...data, lenderId: lender.lender.id, omicronBdmId: lender.lender.omicronBdmId } : data;
    });
    setLoanLenders(updateList);
    closeInactiveLenderConfirmModal()
  }

  const closeInactiveLenderConfirmModal = () => {
    setInactiveLenderConfirmModal({ visible: false, saveLoading: false, financier: {} });
  };

  const displayInactiveLenderConfirmModal = (lender) => {
    setInactiveLenderConfirmModal({ ...inactiveLenderConfirmModal, visible: true, financier: lender });
  };

  const handleConfirmAddInactiveLender = (confirm, lender) => {
    if(confirm){
      addLoanLender(lender);
    }
    else{
      if(loanLenders.length == 1){
        setLoanLenders([
          ...tempLoanLenders
        ]);
      }else {
        handleDelete(lender.index);
      }
      closeInactiveLenderConfirmModal()
    }
  };

  return (
    <React.Fragment>
      <AddLendersModal
        loanLenders={tempLoanLenders}
        list={lendersList}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleSave={handleSave}
        handleChange={handleChange}
        handleLenderChange={handleLenderChange}
        handleDeleteAll={() =>
          setLoanLenders([{ lenderId: null, contributionAmount: null, omicronBdmId: null }])
        }
      />
      <AddInactiveFinancierConfirmationModal
        confirmModal={inactiveLenderConfirmModal}
        handleConfirm={handleConfirmAddInactiveLender}
        message={'This Lender is Profile Paused / Master Switch off are you sure you want to continue?'}
      />
    </React.Fragment>
  );
};
export default AttributedLendersForm;
