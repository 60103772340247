import { Select } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { loansLawFirmLookup } from "../../../actions/lookup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { mustBeArray } from "../../../libs";
import LoanCreateContext from "./LoanCreateContext";
import AddInactiveFinancierConfirmationModal from "./AddInactiveFinancierConfirmationModal";
import { debounce } from "lodash";
const LawFirmLookup = ({ value, className, setLawFirm }) => { 
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();
  const { lawFirm } = useContext(LoanCreateContext);

  const [inactiveLawFirmConfirmModal, setInactiveLawFirmConfirmModal] = useState({
    saveLoading: false,
    visible: false,
    financier: {}
  });

  useEffect(() => {
    dispatchAction(
      loansLawFirmLookup({ cancelToken: signal.token, search: "" })
    ).then(resp => {
      setList(mustBeArray(resp));
    });
  }, []);

  useEffect(() => {
    updateList(list);
  }, [lawFirm, value]);

  const updateList = currentList => {
    const listIncludesLawFirm = list.find(o => o.id === value);
    if (!listIncludesLawFirm && lawFirm) {
      setList([...list, { id: value, name: lawFirm }]);
      return;
    }
    setList(currentList);
  };

  const handleChange = value => {
    const lawFirm = list.find(b => {
      return b.id === value;
    });
    handleLawFirmChange(lawFirm);
  }

  const handleLawFirmChange = lawFirm => {
    if(lawFirm.isMasterSwitchOn && lawFirm.isActive)
      setLawFirm(lawFirm)
    else{
      displayInactiveLawFirmConfirmModal(lawFirm);
    } 
  }

  const handleConfirmAddInactiveLawFirm = (confirm, lawFirm) => {
    if(confirm)
    {
      setLawFirm(lawFirm);
    }
    else{
      handleSearch('');
    }

    handleInactiveLawFirmConfirmModalClose();
    
  }

  const displayInactiveLawFirmConfirmModal = lawFirm => {
    setInactiveLawFirmConfirmModal({ ...inactiveLawFirmConfirmModal, visible: true, financier: lawFirm });
  };

  const handleInactiveLawFirmConfirmModalClose = () => {
    setInactiveLawFirmConfirmModal({ visible: false, saveLoading: false, financier: {} });
  };

  const handleSearch = e => {
    dispatchAction(
      loansLawFirmLookup({ cancelToken: signal.token, search: e })
    ).then(resp => {
      setList(mustBeArray(resp));
    });
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300));

  return (
    <React.Fragment>
      <div className="flex flex-align-center m-r-16 m-b-10">
      <b>Lawfirm</b>
      <Select
        className="w-280 m-l-6"
        value={value}
        onChange={value =>
          handleChange(value)
        } 
        allowClear={false}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
      > 
            {list.map(lawFirm => {
          return (
            <Select.Option key={lawFirm.id} value={lawFirm.id}>
              {lawFirm.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
    <AddInactiveFinancierConfirmationModal
      confirmModal={inactiveLawFirmConfirmModal}
      handleConfirm={handleConfirmAddInactiveLawFirm}
      message={'This Law Firm is Profile Paused / Master Switch off are you sure you want to continue?'}
    />
    </React.Fragment>
  );
};

export default LawFirmLookup;
