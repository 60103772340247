import { Icon, Pagination } from "antd";
import React, {useEffect, useMemo, useState } from "react";
import {
  FinderUtilitySearchModal,
  FinderHeader,
  defaultFilters,
  FinderSidebar,
  FinderUtilityContext,
  finderFilterTypes,
  LenderBrokerFilter,
  FinderUserView
} from "../../components/FinderUtility";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/loading";
import {
  errorDisplay,
  isNullEmptyOrUndefined,
  mustBeArray,
  parseItem,
  evaluatePolicy
} from "../../libs";
import { ADMIN_USER, DUAL_USER, BROKER_USER, LENDER_USER, LAW_FIRM_USER } from "../../constants/systemUsers";
import {
  finderBrokersLookups,
  finderLendersLookups,
  finderPiorityLookup,
  finderRadiusLookups,
  finderScenarioLookups,
  finderSecurityTypesLookups,
  finderSettlementDateLookups,
  finderStatesAndSuburbsLookups,
  finderTermLookups
} from "../../actions/finderUtility";
import _, { isEmpty } from "lodash";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import history from "../../routes/history";
import { isLenderUserPolicy, isLenderOwnProfilePolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isBrokerUserPolicy, isBrokerOwnProfilePolicy } from "../../Common/Authorization/policies/isBrokerUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { isLawFirmUserPolicy, isLawFirmOwnProfilePolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import { FINDER_FILTER, FINDER_USER_TYPE } from "../../constants";
import authService from "../../actions/AuthorizeService";
import { useFilterView } from "./use-filter-view";
import FinderUserViewMobile from "../../components/FinderUtility/UserDisplayCard/mobileIndex";
import {
  EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER,
  LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
  LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER,
  BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
  LAWFIRM_PROFILE_ROUTES_SYSTEM_USER,
  PROFILE_EDIT_OPTION,
  DEFAULT_ROUTES_SYSTEM_USER
} from "../../constants/routeOptions";
import { SuperSEO } from "react-super-seo";

const getSearchText = filters => {
  const {
    lowerRate,
    higherRate,
    lvr,
    establishmentFee,
    principal,
    radius,
    suburbAndState,
    priority,
    scenario,
    securityType,
    settlementDate,
    term,
    brokerStarsFromLender,
    lenderStarsFromBroker
  } = finderFilterTypes;

  const searchParam = mustBeArray(filters).reduce((a, b) => {
    let search = "";
    switch (b.type) {
      case lowerRate:
        search = !isNullEmptyOrUndefined(b?.value?.low)
          ? `minLowerRate=${b.value.low}&maxLowerRate=${b.value.high}`
          : "";
        break;
      case higherRate:
        search = !isNullEmptyOrUndefined(b?.value?.low)
          ? `minHigherRate=${b.value.low}&maxHigherRate=${b.value.high}`
          : "";
        break;
      case lvr:
        search = !isNullEmptyOrUndefined(b?.value?.low)
          ? `minLvr=${b.value.low}&maxLvr=${b.value.high}`
          : "";
        break;
      case establishmentFee:
        search = !isNullEmptyOrUndefined(b?.value?.low)
          ? `minEstablishmentFee=${b.value.low}&maxEstablishmentFee=${b.value.high}`
          : "";
        break;
      case term:
        search = b?.value?.low
          ? `minTermKey=${b.value.low}&maxTermKey=${b.value.high}`
          : "";
        break;
      case priority:
        search = b?.value ? `priorityKey=${b.value}` : "";
        break;
      case brokerStarsFromLender:
        search = b?.value ? `brokerStarsFromLender=${b.value}` : "";
        break;
      case lenderStarsFromBroker:
        search = b?.value ? `lenderStarsFromBroker=${b.value}` : "";
        break;
      case scenario:
        search = b?.value ? `scenarioKey=${b.value}` : "";
        break;
      case settlementDate:
        search = b?.value ? `settlementDateKey=${b.value}` : "";
        break;
      case securityType:
        if (mustBeArray(b?.value).length === 2) {
          search = `securityTypeKey=${b.value[0]}&securitySubTypeKey=${
            b.value[1]
          }`;
        }
        if (mustBeArray(b?.value).length === 1) {
          search = `securityTypeKey=${b.value[0]}`;
        }
        break;
      case principal:
        search = b?.value?.low
          ? `minPrincipal=${b.value.low}&maxPrincipal=${b.value.high}`
          : "";
        break;
      case radius:
        search = b?.value ? `radiusSearchkey=${b.value}` : "";
        break;
      case suburbAndState:
        const stateAndSub = b.value?.split(", ");
        search = stateAndSub?.[0]
          ? `suburb=${stateAndSub?.[0]}&state=${stateAndSub?.[1]}`
          : "";
        break;
    }
    return search ? `${a}${search}&` : a;
  }, "");
  return searchParam;
};

const FinderUtilityView = ({ generalView }) => {
  const {viewFilter, toggleViewFilter, smallView} = useFilterView();
  const dispatchAction = useDispatch();
  const user = useSelector(state => state?.authorization?.user);
  const signal = axios.CancelToken.source();
  const [list, setList] = useState({
    data: [],
    page: 1,
    loading: false,
    total: 0
  });

  const individualUser = useSelector(state => state.authorization && state.authorization.individualUser);
  const [userType, setUserType] = useState(undefined);
  const [isSystemUser, setIsSystemUser] = useState(undefined);
  const [suburbList, setSuburbList] = useState([]);
  const [radiusList, setRadiusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [securityList, setSecurityList] = useState([]);
  const [settlementList, setSettlementList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [filters, setFilters] = useState({
    value: defaultFilters,
    loading: true
  });
  
  const [modalProps, setModalProps] = useState({
    title: "Lvr",
    type: "lvr",
    visible: false,
    value: { low: undefined, high: undefined }
  });

  const getDefaultUserType = async () => {
    if (generalView) {
      return setUserType(BROKER_USER);
    }
    
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    
    if(systemUser)
     setIsSystemUser(systemUser);

    const savedUserType = localStorage.getItem(FINDER_USER_TYPE);

    const lenderUser = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );

    const brokerUser = await isBrokerUserPolicy(
      user.roles,
      user.permissions,
      user
    );

    if (savedUserType) {
      return setUserType(savedUserType);
    }
      
    if (systemUser || (lenderUser && brokerUser))
      return setUserType(BROKER_USER);

    if (lenderUser) return setUserType(BROKER_USER);
    return setUserType(LENDER_USER);
    
  };

  const getTermList = () => {
    dispatchAction(
      finderTermLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setTermList(data);
    });
  };

  const getSuburbList = () => {
    dispatchAction(
      finderStatesAndSuburbsLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setSuburbList(data);
    });
  };

  const getSecurityList = () => {
    dispatchAction(
      finderSecurityTypesLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setSecurityList(data);
    });
  };

  const getPriorityList = () => {
    dispatchAction(
      finderPiorityLookup({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setPriorityList(data);
    });
  };

  const getSettlementList = () => {
    dispatchAction(
      finderSettlementDateLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setSettlementList(data);
    });
  };

  const getScenarioList = () => {
    dispatchAction(
      finderScenarioLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setScenarioList(data);
    });
  };

  const getRadiusList = () => {
    dispatchAction(
      finderRadiusLookups({ cancelToken: signal.token, generalView })
    ).then(resp => {
      const data = mustBeArray(resp);
      setRadiusList(data);
    });
  };

  const checkAuthentication = async() => {
    const authenticated = await authService.isAuthenticated();
    if(authenticated) history.push('/finder-utility')
  }

  useEffect(() => {
    checkAuthentication();
    getSuburbList();
    getTermList();
    getSecurityList();
    getPriorityList();
    getSettlementList();
    getScenarioList();
    getRadiusList();
    getDefaultUserType();
    dispatchAction(
      updateHeader({
        header: {
          title: "",
          action: "",
          page: "finder-utility",
          enableBack: false,
          showSettings: generalView ? false : true,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: generalView ? false : true,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
    setTimeout(() => {
      setFilters({
        value: generalView
          ? defaultFilters
          : parseItem(localStorage.getItem(FINDER_FILTER), defaultFilters),
        loading: false
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (list.loading) {
      getData();
    }
  }, [list.loading]);

  useEffect(() => {
    if (!filters?.loading) {
      setTimeout(() => {
        setList({ ...list, loading: true, total: 0 });
      }, 200);
    }
  }, [userType, isSystemUser, filters]);

  const getData = () => {
    const action =
      userType === LENDER_USER
        ? finderLendersLookups
        : userType === BROKER_USER
        ? finderBrokersLookups
        : null;
    if (action) {
      const search = getSearchText(filters?.value);
      dispatchAction(
        action({
          cancelToken: signal.token,
          search: `page=${list.page}&${search}`,
          generalView,
          isSystemUser
        })
      ).then(resp => {
        if (!_.isEmpty(mustBeArray(resp?.data?.errors))) {
          errorDisplay(resp?.data?.errors);
          setList({ ...list, loading: false, total: 0, data: [] });
          return;
        }
        setList({
          ...list,
          total: resp?.total || 0,
          data: mustBeArray(resp?.rows),
          loading: false
        });
      });
    }
  };

  useEffect(() => {
    updateUsersRouteOptions();
  }, [window.location.href]);
  
  const updateUsersRouteOptions = async () => {

    let isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    let systemUser = await isSystemUserPolicy(user.roles, user.permissions, user);
    let isLawFirm = await isLawFirmUserPolicy(user.roles);

    const isOwnProfile =
      (await evaluatePolicy(user, isBrokerOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLenderOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLawFirmOwnProfilePolicy(user.id)));

    const roleType = systemUser
      ? ADMIN_USER
      : isLawFirm
      ? LAW_FIRM_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : isBroker
      ? BROKER_USER
      : "";
     
      let newRouteOptions;
      if(roleType === DUAL_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER;
      }
      else if(roleType === LENDER_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER
      }
      else if(roleType === BROKER_USER){
        newRouteOptions = BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER
      }
      else if(roleType === LAW_FIRM_USER){
        newRouteOptions = LAWFIRM_PROFILE_ROUTES_SYSTEM_USER;
      }
      else {
        newRouteOptions = DEFAULT_ROUTES_SYSTEM_USER;
      }

      newRouteOptions = [...newRouteOptions].map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });

      const menuOptions = isOwnProfile
        ? [...EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, ...PROFILE_EDIT_OPTION]
        : [];
      
      dispatchAction(
        await updateRouteOptions({
          routeOptions: _.concat(newRouteOptions, menuOptions)
        })
      );
  }
  
  const handleModalClose = () => {
    setModalProps({ ...modalProps, visible: false });
  };

  const handleSearch = value => {
    const tempFilters = [...filters?.value].reduce((a, b) => {
      return [...a, modalProps.type === b.type ? { ...b, value } : b];
    }, []);
    setFilters({ value: tempFilters, loading: false });
    updateStoredFilters(tempFilters);
    handleModalClose();
  };

  const updateStoredFilters = newValue => {
    if (!generalView) {
      localStorage.setItem(FINDER_FILTER, JSON.stringify(newValue));
    }
  };

  const handleClearAll = () => {
    setFilters({ value: defaultFilters, loading: false });
    updateStoredFilters(defaultFilters);
  };

  const displayFilter = data => {
    setModalProps({ ...data, visible: true });
  };

  const handleDelete = ({ type, defaultValue, index }) => {
    let tempFilter = [...filters?.value].reduce((a, b) => {
      if (isNullEmptyOrUndefined(index))
        return [...a, b.type === type ? { ...b, value: defaultValue } : b];
      if (b.type === type) {
        let currentValue = filters?.value.find(o => o.type === type)?.value;
        return [...a, { ...b, value: index === 0 ? [] : [currentValue[0]] }];
      }
      return [...a, b];
    }, []);
    setFilters({ value: tempFilter, loading: false });
    updateStoredFilters(tempFilter);
  };

  const handleUserTypeChange = value => {
    setUserType(value);
    setList({ ...list, page: 1, total: 0 });
    if (!generalView) {
      localStorage.setItem(FINDER_USER_TYPE, value ? value : "");
    }
  };

  const handlePageChange = page => {
    setList({ ...list, page, loading: true, total: 0 });
  };
  const handleViewDetails = id => {
    const search = getSearchText(filters?.value);
    const url = generalView ?
     `/general/${userType}s/${id}?searchMatch=true&${search}` 
     : `/users/${id}/${ userType === LENDER_USER ? "lender" : "broker"}?searchMatch=true&${search}`;
    window.open(url, '__blank');
  };

  const selectedSubAndState = useMemo(
    () =>
      mustBeArray(filters?.value).find(
        o => o.type === finderFilterTypes.suburbAndState
      )?.value,
    [filters?.value]
  );

  return (
    <FinderUtilityContext.Provider
      value={{
        displayFilter,
        handleDelete,
        suburbList,
        setSuburbList,
        radiusList,
        setRadiusList,
        priorityList,
        setPriorityList,
        scenarioList,
        setScenarioList,
        securityList,
        setSecurityList,
        settlementList,
        setSettlementList,
        termList,
        setTermList,
        userType,
        list,
        handleViewDetails,
        selectedSubAndState,
        filters: filters?.value,
        generalView
      }}
    >
      <SuperSEO
        title="Omicron Private Mortgage Network"
        description="Our Finder allows you to search through our database of hundreds of recently closed loans to find the commercial broker who can do your loan (because they just did your loan for someone else)."
      />
      {!smallView && <FinderHeader filters={filters?.value} />}
      <div gutter={24} className={`finder-content ${smallView ? "m-t-20":"m-t-30"}`}>
        {
          (!smallView || viewFilter) &&
          <div className="text-right finder-filter">
          <div
            className="color-success hand f-s-10 m-b-10"
            onClick={handleClearAll}
          >
            Clear All
          </div>
          <FinderSidebar filters={filters?.value} />
          </div>
        }
        
        <div className={`${smallView ? "default-body" : ""} flex-1-1`}>
            <div className={smallView ? "flex space-between align-center m-b-16" : ""}>
              {
                smallView &&
                <Icon type="menu"  className="color-primary finder-menu-icon hand" onClick={toggleViewFilter}/>
              }
              {!generalView &&
              <>
              <div className={` float-right ${smallView ?  "" : "m-b-20 m-r-62"}`}>
                <LenderBrokerFilter
                  value={userType}
                  onChange={handleUserTypeChange}
                />
              </div>
              <div className="clearfix" />
              </>
          }
            </div>
          {
            smallView &&
            <FinderHeader filters={filters?.value} smallView={smallView} />
          }

          {list.loading ? (
            <Loading className="m-t-20" />
          ) : isEmpty(mustBeArray(list.data)) ? (
            <div className="m-t-20">{`There are no ${
              userType === LENDER_USER ? "lenders" : "brokers"
            } matching your search criteria.`}</div>
          ) : (
            mustBeArray(list.data).map(user => {
              return smallView ? <FinderUserViewMobile user={user} key={user?.email} /> : <FinderUserView user={user} key={user?.email} />;
            })
          )}
          <Pagination
            hideOnSinglePage
            current={list.page}
            total={list.total}
            onChange={handlePageChange}
            pageSize={20}
            className="m-b-12"
          />
          </div>
        {/* </Col> */}
      </div>

      <FinderUtilitySearchModal
        {...modalProps}
        handleClose={handleModalClose}
        handleSearch={handleSearch}
      />
    </FinderUtilityContext.Provider>
  );
};

export default FinderUtilityView;
