/* eslint-disable */

import _ from "lodash";
import { http } from "./http";
import atob from "atob";
import { message } from "antd";
import {
  entityType,
  lectureType,
  sectionContentType,
  workflowContentTypes
} from "../constants/entities";
import axios from "axios";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../constants/moment";
import { ADMIN_USER, BROKER_USER, LENDER_USER } from "../constants/systemUsers"; 

/**
 * @typedef {<T>(action:T) => ReturnType<T>} DispatchAction
 */

export const getIdToken = () => {
  const idToken = localStorage.getItem("id_token");
  if (!idToken) {
    throw new Error("No id token found");
  }
  return idToken;
};

export const dataUrlToBase64String = dataUrl => {
  return dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
};

export const getBase64Image = (imgUrl, callback) => {
  var img = new Image();

  // onload fires when the image is fully loadded, and has width and height

  img.onload = function() {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png"),
      dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

    callback(dataURL); // the base64 string
  };

  // set attributes and src
  img.setAttribute("crossOrigin", "anonymous"); //
  img.src = imgUrl;
};

export const errorDisplay = errors => {
  mustBeArray(errors).map((error, i) => {
    return message.error(error && error.message);
  });
};

export const errorDisplayCustom = errors => {
  mustBeArray(errors).map((error, i) => {
    return message.error(error);
  });
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  if (!accessToken) {
    throw new Error("No access token found");
  }
  return accessToken;
};

export const mapWorkflowStepType = contentType => {
  let workflowType = _.find(
    workflowContentTypes,
    wc => wc.type === contentType
  );
  return workflowType.value;
};

export const mapContentType = type => {
  let contentType = _.find(entityType, et => et.type === type);
  return contentType;
};

export const mapSectionContentType = type => {
  let sectionConent = _.find(sectionContentType, wc => wc.value === type);
  return sectionConent.attr;
};
export const mapLectureType = type => {
  let lye = _.find(lectureType, wc => wc.value === type);
  return lye.attr;
};

export const getClaimFromToken = (token, claim) => {
  var payload = token.split(".")[1];
  var bin = atob(payload);
  var obj = JSON.parse(bin);
  return obj[claim];
};

export const isNulledUndefined = value => {
  return _.isNull(value) || _.isUndefined(value);
};

export const downloadAs = (url, name) => {
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream"
      },
      responseType: "blob"
    })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      console.log("error", err);
    });
};

export function defaultIfUndefinedOrNull(value, defaultValue) {
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value;
}

export async function evaluatePolicy(user, policy) {
  return await policy(user.roles, user.permissions, user);
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getDataUrlFromBlob = blob =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(blob);
  });

export const addQueryParams = (baseUrl, options) => {
  const paramString = _.isEmpty(options) ? "" : "?" + buildQueryParams(options);
  return baseUrl + paramString;
};

export const buildQueryParams = params => {
  var esc = encodeURIComponent;

  return !_.isUndefined(params)
    ? Object.keys(removeUndefined(params))
        .map(k => {
          var queryString = _.isObject(params[k])
            ? buildFilterParams(params[k])
            : esc(params[k]);
          return esc(k) + "=" + queryString;
        })
        .join("&")
    : "";
};

export const toPaginationQueryParams = (params = {}) => {
  let paginationParams = {};
  paginationParams.sort = params.order;
  paginationParams.orderBy = params.field;
  paginationParams.page = params.page;
  paginationParams.pageSize = params.pageSize;

  return paginationParams;
};

export const checkUndefinedInObject = object => {
  let allValuesEntered = true;
  if (_.isObject(object) && !_.isEmpty(object)) {
    _.forEach(Object.values(object), function(o) {
      if (o === "" || o === null || o === undefined) {
        allValuesEntered = false;
      }
    });
  } else {
    allValuesEntered = false;
  }
  return allValuesEntered;
};

export const removeEmptyValuesInObject = object => {
  let newObject = _.clone(object);
  _.forEach(Object.keys(object), o => {
    if (object[o] === "" || _.isNull(object[o]) || _.isUndefined(object[o])) {
      delete newObject[o];
    }
  });
  return newObject;
};

export const safeParseObject = (root, objString) => {
  const objArray = objString.split(".");
  let value = root;
  objArray.forEach(obj => {
    value = value && value[obj];
  });
  return value;
};

export const handleFilterObject = obj => {
  let temp = _(obj)
    .omitBy(_.isEmpty)
    .assign(_.isUndefined)
    .assign(_.isNull)
    .each((index, value, item) => {
      if (value === "isActive") {
        item.isActive =
          Number(index) === 0 ? "" : Number(index) === 1 ? "true" : "false";
      }
      return item;
    });

  return _(temp)
    .omitBy(_.isEmpty)
    .omitBy(_.isNull)
    .value();
};

export const buildFilterParams = params => {
  var esc = encodeURIComponent;

  // Special case handling for moment, we need to convert it first to Date before parsign to string
  if (params instanceof moment) {
    return esc(params.toDate().toJSON());
  }

  return !_.isUndefined(params)
    ? Object.keys(removeUndefined(params))
        .map(k => {
          var queryString = _.isObject(params[k])
            ? JSON.stringify(params[k])
            : esc(params[k]);
          return esc(k) + ":" + queryString;
        })
        .join(",")
    : "";
};

export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0;
    var v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const checkIfValueExists = (value, alternateValue) => {
  if (value) return value;
  return alternateValue;
};

export const removeUndefined = params => {
  _.each(params, function removeUndefined(value, key) {
    if (_.isUndefined(value)) {
      delete params[key];
    }
    if (value === "") {
      delete params[key];
    }
    if (_.isNull(value)) {
      delete params[key];
    }
  });
  return params;
};

export const mustBeArray = items => {
  return _.isArray(items) ? items : [];
};

export const disableBrowserAutocomplete = () => {
  let i;
  const el = document.getElementsByClassName("ant-select-search__field");
  for (i = 0; i < el.length; i++) {
    el[i].setAttribute("autocomplete", "registration-select");
  }
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const requestBuilder = async (
  id,
  baseUrl,
  options,
  _filters,
  cancelToken
) => {
  const endUrl = id ? `${baseUrl}/${id}` : `${baseUrl}`;
  const paramString = _.isEmpty(options) ? "" : "?" + buildQueryParams(options);
  return await http.get(endUrl + paramString, cancelToken);
};

export const requestBuilderBlob = async (id, baseUrl, options, cancelToken) => {
  const endUrl = id ? `${baseUrl}/${id}` : `${baseUrl}`;
  const paramString = _.isEmpty(options) ? "" : "?" + buildQueryParams(options);
  return await http.getPdf(endUrl + paramString, cancelToken);
};

export const fetchWithId = async (id, baseUrl, options) => {
  const endUrl = id ? `${baseUrl}/${id}?` : `${baseUrl}?`;
  return await http.get(endUrl + buildQueryParams(options));
};

export const dispatcher = (dispatch, data, action_type, storable) => {
  const actionData = { type: action_type, data: data, status: true };
  return storable ? actionData : dispatch(actionData);
};

export const templateEngine = function(tpl, data) {
  var re = /<%([^%>]+)?%>/g,
    match;
  while ((match = re.exec(tpl))) {
    tpl = tpl.replace(match[0], data[match[1]]);
  }
  return tpl;
};

export const filterGeoAddressLocation = results => {
  const address = {};
  if (_.first(results)) {
    _.each(results[0].address_components, address_component => {
      switch (_.first(address_component.types)) {
        case "country":
          address["country"] = address_component.long_name;
          break;
        case "street_number":
          address["address1"] = address_component.long_name;
          break;
        case "establishment":
          address["address1"] = _.isUndefined(address["address1"])
            ? address_component.long_name
            : address["address1"] + " " + address_component.long_name;
          break;
        case "route":
          address["address1"] = _.isUndefined(address["address1"])
            ? address_component.long_name
            : address["address1"] + " " + address_component.long_name;
          break;
        case "postal_code":
          address["postalcode"] = address_component.long_name;
          break;
        case "administrative_area_level_1":
          address["state"] = address_component.short_name;
          break;
        case "locality":
          address["city"] = address_component.long_name;
          break;
        default:
          break;
      }
    });
  }
  return address;
};

export const currencyFormater = (value, negative) => {
  return `${
    (negative || Number(value) < 0) && Math.abs(value).toFixed(2) !== "0.00"
      ? "-"
      : ""
  }$${Number(Math.abs(value)).format("0,0.00")}`;
};

/**
 * @returns {string}
 */
export const asDefaultDisplayFormatDate = dateString => {
  if (typeof dateString === "string" && dateString !== "") {
    dateString = moment.utc(dateString).local();
    return moment(dateString).format(DEFAULT_DATE_FORMAT);
  }

  if (dateString instanceof moment) {
    return dateString.format(DEFAULT_DATE_FORMAT);
  }

  console.warn("could not parse passed object to a date format");
  return "";
};

export const parseMomentPropsToDate = obj => {
  Object.keys(obj ?? {}).forEach(key => {
    const prop = obj[key];
    if (prop instanceof moment) {
      obj[key] = prop.toDate();
    }
  });

  return obj;
};

/**
 * Runs the lambda action if the provided valid is not null, else it returns a null value
 * @template T,TReturn
 * @param {T} subject
 * @param {(subject: T) => TReturn} action
 * */
export const doIfNotNull = (subject, action) =>
  subject != null ? action(subject) : null;

export const jsUcfirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const move = (array, moveIndex, toIndex) => {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length)
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ];
  }
  return array;
};

export const evalObject = object => {
  try {
    return mustBeArray(JSON.parse(object));
  } catch (error) {
    return [];
  }
};

export const getAddressString = address => {
  if (!address) return "";
  return `${address.addressLine1 ? `${address.addressLine1}, ` : ""}
          ${address.addressLine2 ? `${address.addressLine2}, ` : ""}
          ${address.addressSuburb ? `${address.addressSuburb}, ` : ""}
          ${address.addressStateKey ? `${address.addressStateKey} ` : ""}${
    address.addressPostCode ? `${address.addressPostCode}` : ""
  }`;
};
export const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  }
};

export const getProductType = ({ field }) => {
  switch (field) {
    case "firstRanking":
      return "first-ranking";
    case "secondRanking":
      return "second-ranking";
    default:
      return field;
  }
};

export const getUsersProfileType = ({
  isSystemUser,
  profileType,
  isOwnProfile
}) => {
  if (isSystemUser) return ADMIN_USER;
  if (profileType === "lender") {
    return isOwnProfile ? LENDER_USER : BROKER_USER;
  }
  return isOwnProfile ? BROKER_USER : LENDER_USER;
};

export const userHasActionRights = ({
  profileType,
  isOwnProfile,
  isSystemUser
}) => {
  if (isSystemUser || isOwnProfile) return true;
  return profileType !== "lender";
};

export const millisecondsInADay = () => 86400000;

export const updateAmountFields = ({ actionType, value }) => {
  if (actionType === "render") {
    const newValue = `${value}`.replace("$", "");
    return newValue;
  } else {
    return `$${value}`;
  }
};

export const updatePercentageFields = ({ actionType, value }) => {
  if (actionType === "render") {
    const newValue = `${value}`.replace("%", "");
    return newValue;
  } else {
    return `${value}%`;
  }
};

export const getActiveKey = ({ activeKey, profileType }) => {
  switch (activeKey && activeKey.toLowerCase()) {
    case "matches":
      return "matches";
    case "filenotes":
      return "fileNotes";
    case "details":
      return profileType === "lender" ? "3" : "details";
    case "firstranking":
      return "4";
    case "secondranking":
      return "5";
    case "construction":
      return "6";
    case "mezzanine":
      return "7";
    case "landbank":
      return "8";
    case "rural":
      return "9";
    case "loanCriteria":
      return "loanCriteria";
    default:
      return null;
  }
};

export const isDataVerified = data => {
  return data && data.isVerified ? true : false;
};

export const isCategoryOffered = ({ data, category }) => {
  return data && data.categoriesOffered && data.categoriesOffered[category]
    ? true
    : false;
};

export const isNullEmptyOrUndefined = (data) => {
  return (data ===null || data === undefined || data === "");
}

export const parseItem = (item, defaultValue) => {
  try{
    const parsedItem = JSON.parse(item);
    return parsedItem;
  }
  catch(err){
    return defaultValue
  }
}

export const constructUrl = website => {
  try {
    var url = new URL(website);
    return url;
  } catch (firstAttemptErr) {
    try{
      var url = new URL('https://' + website);
      return url;
    }catch (secondAttemptErr){
      return url;
    }
    
  }
}


export const getRoundedRatings = value => {
  if(!value || value === 5) return value;
  const minValue = Math.floor(value);
  if(value >= minValue + 0.5) return minValue + 0.5;
  return minValue;
}